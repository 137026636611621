import { 
  IReduxAction, 
  IPrize, 
  IQuestion, 
  IAnswerDraft,
  IPersistanceTracker,
  ISyncTracker,
  Feedback,
  User,
  Answer,
  Transaction
} from '../../app-types';
import C from '../../app-types/action-types';
import { combineReducers } from 'redux';


const token = (state: string = '', action: IReduxAction): string => {
  switch (action.type) {
    case C.SET_TOKEN:
      return action.payload;  
    case C.LOGOUT:
      return '';
    default:
      return state;
  }
}

const transactions = (state: Transaction[] = [], action: IReduxAction):Transaction[] => {
  switch (action.type) {
    case C.SET_TRANSACTIONS:
      return action.payload;
    case C.APPEND_TRANSACTIONS: 
      return [...state, ...action.payload]  
    case C.CLEAR_TRANSACTION:
      return state.filter(item => item.id !== action.payload)
    case C.LOGOUT:
      return [];
    default:
      return state;
  }
}

const backgroundFetching = (state: boolean = false, action:IReduxAction):boolean => {
  switch (action.type) {
    case C.START_BACKGROUND_FETCHING:
      return true;
    case C.CANCEL_BACKGROUND_FETCHING:
      return false;  
    case C.LOGOUT:
      return false;
    default:
      return state;
  }
}

const isFetching = (state: boolean = false, action: IReduxAction) : boolean => {
  switch (action.type) {
    case C.START_FETCH:
      return true;  
    case C.CANCEL_FETCH:
      return false;  
    case C.LOGOUT:
      return false;    
    default:
      return state;
  }
}

const prizes = (state: IPrize[] = [], action: IReduxAction) : IPrize[] => {
  switch( action.type) {
    case C.SET_PRIZES:
      return action.payload;
    case C.APPEND_PRIZES:
      return [...state , ...action.payload];
    case C.LOGOUT:
      return [];      
    default:
      return state;
  }
}

const questions = (state: IQuestion[] = [], action: IReduxAction) : IQuestion[] => {
  switch (action.type) {
    case C.SET_QUESTIONS:
      return action.payload;
    case C.CLEAR_QUESTIONS:
    case C.LOGOUT:
      return [];  
    default:
      return state;
  }
}

const syncTracker = (
  state: ISyncTracker = {
    prize: 0, 
    suggestion: 0, 
    user: 0, 
    question: 0, 
    drafts: 0, 
    answers: 0,
    answersFeedback: 0
  }, 
  action: IReduxAction) : ISyncTracker => {

  switch (action.type) {
    case C.SET_QUESTIONS:
      return {...state, question: Date.now()};
    case C.SET_PRIZES:
      return {...state, prize: Date.now()};
    case C.SAVE_DRAFT:
      return {...state, drafts: Date.now()};
    case C.SET_USER_DATA:
      return {...state, user: Date.now()};
    case C.APPEND_ANSWERS:
    case C.SET_ANSWERS:
      return {...state, answers: Date.now()};
    case C.SET_ANSWERS_FEEDBACK:
      return {...state, answersFeedback: Date.now()};
    case C.CLEAR_ANSWERS_FEEDBACK:
      return {...state, answersFeedback: 0, answers: 0};
    case C.LOGOUT:
      return {prize: 0, suggestion: 0, user: 0, question: 0, drafts: 0, answers: 0, answersFeedback: 0};
    default:
      return state;
  }
} 

const drafts = (state : IAnswerDraft[] = [], action: IReduxAction) : IAnswerDraft[] => {
  switch (action.type) {
    case C.LOGOUT:
      return [];
    case C.LOAD_DRAFTS:
      return action.payload;
    case C.PURGE_DRAFTS:  
    case C.CLEAR_DRAFT:
      return state.filter( draft => draft.questionId !== action.payload.questionId);
    case C.SAVE_DRAFT:
      if(state.some( draft => draft.questionId === action.payload.questionId)){                
        return state.reduce(( acc, cur ) => (
          cur.questionId === action.payload.questionId
            ? [action.payload, ...acc.filter( each => each.questionId !== action.payload.questionId)]
            : acc
        ), state)
      }else{
        return [action.payload, ...state].slice(0, 10)
      }      
    default:
      return state;
  }
}

const persistanceTracker = (state:IPersistanceTracker = {drafts: true}, action: IReduxAction) => {
  switch (action.type) {
    case C.CLEAR_DRAFT:
    case C.SAVE_DRAFT:
      return {...state, drafts: false}  
    case C.PERSIST_DRAFTS:
      return {...state, drafts: true}
    case C.LOGOUT:
      return {drafts: true}
    default:
      return state;
  }
}

const feedback = (
  state: Feedback = {message: '', type: ''}, action: IReduxAction):Feedback => {
  switch (action.type) {
    case C.SET_MESSAGE:
      return action.payload;
    case C.CLEAR_MESSAGE:
      return {message: '', type: ''};
    default:
      return state;
  }
}

const userData = (
  state:User = {name: '', balance: 0, answers: {sent: 0, pending: 0, feedback: 0}}, 
  action:IReduxAction):User => {
    
  switch (action.type) {
    case C.SET_USER_DATA:
      return action.payload  
    case C.LOGOUT:
      return {name: '', balance: 0, answers: {sent: 0, pending: 0, feedback: 0}};
    default:
      return state;
  }
}

const answersFeedback = (state:Answer[] = [], action:IReduxAction) : Answer[] => {
  switch (action.type) {
    case C.SET_ANSWERS_FEEDBACK:
      return action.payload;
    case C.CLEAR_ANSWERS_FEEDBACK:
      return state.filter((answer) => answer.id !== action.payload);
    case C.FEEDBACK_CREATED_OFFLINE:
      const answer:Answer|undefined = state.find((ea) => ea.id = action.payload.id );
      if(answer){ 
        answer.allow_edit = false;
        answer.is_pending_evaluation = true;
        answer.details = action.payload.details;
        
        return state.map((item) => item.id === action.payload.id ? answer : item);
      }else{
        return state
      }      
    case C.LOGOUT:
      return [];
    default:
      return state;
  }
}

const answers = ( state:Answer[] = [], action:IReduxAction): Answer[] => {
  switch (action.type) {
    case C.SET_ANSWERS:
      return action.payload  
    case C.APPEND_ANSWERS: 
      return [...state, ...action.payload]
    case C.LOGOUT:
      return [];
    case C.SET_ANSWERS_FEEDBACK:
      return state.filter(answer => !action.payload.some((ea:Answer) => ea.id === answer.id))
    default:
      return state;    
  }
}

const rootReducer = combineReducers({
  isFetching,
  backgroundFetching,
  prizes,
  questions,
  token,
  drafts,
  syncTracker,
  persistanceTracker,
  feedback,
  userData,
  answers,
  answersFeedback,
  transactions
})

export type RootReducer = ReturnType<typeof rootReducer> 
export default rootReducer