import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ReduxState } from '../../app-types';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';


const Voucher= () => {
  const { id } = useParams();
  const transaction = useSelector((state: ReduxState) => state.transactions.filter( (item) => item.id === parseInt(id) )[0] || {} )
  console.log(transaction && transaction.voucher);
  
  return( 
    transaction && transaction.voucher
    ? (
      <div className={css(styles.container)}>
        <div className={css(styles.borderColor)}>
          <div className ={css(styles.contPaddingLarger)}>
            <div className={css(styles.content)}> 
              <Typography 
                variant="h4" 
                gutterBottom>
                  
                {transaction.voucher}
              </Typography>

              <Typography 
                className={css(styles.textContent)} 
                variant="body2" 
                gutterBottom>

                Use o código para resgatar seu prêmio
              </Typography>
            </div> 
          </div>    
        </div>
      </div>
    )
    : <Redirect to="/solicitacoes"/>
  );
}

export default Voucher;    

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  borderColor:{
    padding: '12px',
    width: '600px',
    height: '200px',
    backgroundColor: 'var(--secondary-color)',
    '@media (min-width: 800px)': {
      width: '50%',
      maxWidth: '800px'
    },
  },

  contPaddingLarger: {
    width: '100%',
    height: '100%',
    padding: '20px',
    backgroundColor: 'white',
  },

  content: {
    width: '100%',
    height: '100%',
    padding: '20px',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    border: '1px dashed'
  },
  textContent: {
    color: 'var(--surface-enabled-color)',
    padding: '10px 0px 0px 0px'
  }, 
});
