import { StashedAnswer } from "../app-types";
import { ThunkResult, fetchAnswerFeedback, fetchAnswers, fetchQuestions } from '../datastore/thunk-actions';
import { SERVER_URL } from '../app-types/constants';
import { getStashedAnswers, setStashedAnswers } from "../datastore/localStorage";
import { startBackgroundFetching, cancelBackgroundFetching } from "../datastore/generalActions";

/**
 * 
 */
const syncStashedAnswers = ():ThunkResult <void> => async (dispatch, getState) => {  
  if(!navigator.onLine) return;
  if(getState().backgroundFetching) return;

  const stashedAnswers = getStashedAnswers();
  const keptAnswers:StashedAnswer[] = [];

  if(stashedAnswers.length === 0) return;
  dispatch(startBackgroundFetching());

  setTimeout(() => dispatch(cancelBackgroundFetching()), 30000);

  for (const answer of stashedAnswers) {
    await submitAnswer(answer, getState().token)
    .catch(()=>{
      keptAnswers.push(answer)
    });
  }
  
  setStashedAnswers(keptAnswers);
  dispatch(cancelBackgroundFetching());
  dispatch(fetchAnswers(0,false));
  dispatch(fetchAnswerFeedback());
  dispatch(fetchQuestions());
}

const submitAnswer = (answerData:StashedAnswer, token: string) => {
  const {details, answerId, questionId} = answerData;
  const itemId = questionId || answerId;
  const isFeedback = answerData.questionId === null;

  return new Promise((resolve, reject) => {
    fetch(`${SERVER_URL}/api/v1/answers${isFeedback ? `/${itemId}`:''}`, {
      method: isFeedback ? "PUT" : "POST",
      headers: { 'Authorization': `Bearer ${token}`},
      body: isFeedback
        ? JSON.stringify({ answer: { details }})      
        : JSON.stringify({ answer: { details, question_id: itemId }})      
    })
    .then( async (response) => {
      if( ![201, 200, 422].includes(response.status)){ 
        throw new Error(response.statusText) 
      }
      
      response.status === 422 ? resolve(false) : resolve(true);      
    })
    .catch(() => {
      reject();
    })
  })
}

export {
  syncStashedAnswers
}