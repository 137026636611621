import React, {useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState, FeedbackType } from '../../app-types';
import { fetchPrizes,submitPrizeTransaction } from '../../datastore/thunk-actions';
import  PrizeItem  from './PrizeItem';
import PasswordConfirmation from './PasswordConfirmation';
import { setMessage } from '../../datastore/generalActions';
import t from '../../app-types/messages';
import EmptyArea from './Uteis/EmptyArea';

function PrizesList (){  
  const [hasFetched, setHasFetched] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [prizeId, setPrizeId] = useState(0);
  const [prize, setPrize] = useState('');
  const prizes = useSelector( (state : ReduxState) => state.prizes);
  const dispatch = useDispatch();

  const closeDialog = () => {
    setPrizeId(0);
    setPrize('');
    setShowDialog(false)
  };

  const openDialog = (prizeId:number, prizeDescription:string) => {
    if( !navigator.onLine){
      dispatch(setMessage(t.not_online, FeedbackType.Warning));
      return;
    }
    setPrizeId(prizeId);
    setPrize(prizeDescription);
    setShowDialog(true)
  };

  const conectar = () => {
    if( hasFetched )return;
    dispatch(fetchPrizes());    
    setHasFetched(true);
  }

  const confirmHandler = (password:string) => {
    dispatch(submitPrizeTransaction(password, prizeId))
    closeDialog();
  }
  
  useEffect(() => conectar());
  
  return(      
    <div className={css(styles.areaPrizes)}>
      { 
        prizes.length > 0 
          ? (
            prizes.map ((prize, i) =>  
                <PrizeItem key={i} prize={prize} handleSubmit={openDialog}/>
            )
          ) 
          : <EmptyArea text="Não há prêmios disponíveis no momento." />
      }

      <PasswordConfirmation 
        show={showDialog}
        title="Confirme sua Senha"
        cancelHandler={closeDialog}
        confirmHandler={confirmHandler}>
        
        Solicitação de troca de prêmio: 
        <br/>
        <strong>{prize}</strong>          
      </PasswordConfirmation>
    </div>      
    );
  }
    
    export default  PrizesList;    
    
    const styles = StyleSheet.create({
      
      areaPrizes: {
        display:'flex',
        flexWrap: 'wrap',
        padding: '1em',
        justifyContent: 'flex-start',
        width: '100%',
        backgroundColor: 'var(--primary-color-dark)',
        overflow: "auto",
        height: 'var(--content-height)',
      },

      areaEmpty: {
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffff',
        color: 'var(--surface-hover-color)',
        justifyContent: 'center',
        width: '100%',
        height: 'var(--content-height-padding)',
      },
      
      hover: {
        ':hover': {
          backgroundColor: 'red'
        }
      },
    });
    