import React, { useState, useEffect} from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { fetchRules } from '../../service/api-server-configuraton';
import { ReduxState } from '../../app-types';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const Rule = () => {
  const [url, setUrl] = useState('');
  const token = useSelector(( state:ReduxState ) => state.token);

  useEffect(() => {
    fetchRules(token).then(( url => {
      console.log( url );
      setUrl(url);
    }));
  },[token]);


  return(
    <div className={css(styles.container)}>
      <Typography className={css(styles.contText)} variant="h3" gutterBottom >Termos de Uso</Typography>
      <Typography variant="subtitle1" display="block" className={css(styles.contText)} gutterBottom >
      Para conferir o regulamento, clique no botão a seguir e consulte todas as informações e regras de participação.
      </Typography>


      <div className={css(styles.contButton)}>
      <Button 
        variant="contained" 
        href={url}
        target="_blank"
        download="regimento"
        color="secondary">
          
        Ver Regulamento
      </Button>
      </div>
      
    </div>
  );
}

export default Rule;

const styles = StyleSheet.create({    
  container: {
    height: 'var(--content-height)',
    width: 'var(--content-width)',
    maxWidth: '1024px',
    backgroundColor: 'var(--primary-color-dark)',
    padding: '30px',
    margin: '0 auto',
    marginTop: '3em',
    overflow: 'auto',
   
  
  },

  contButton: {
    marginTop: '30px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },

  contText: {
    color: 'var(--text-primary-color)',
    textAlign: 'center',
  }

});