import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState, FeedbackType } from '../../app-types';
import { passwordRecovery } from '../../datastore/thunk-actions';
import { Redirect } from 'react-router-dom';


function RecoveryPassword (){
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();  
  const isFetching = useSelector((state:ReduxState) => state.isFetching );
  const [message, messageType] = useSelector((state:ReduxState) => (
    [state.feedback.message, state.feedback.type]));

  const validate = () => {
    let isValid: boolean = true;

    if(email === "" ){
      setEmailError("Campo Obrigatório.");
      isValid = false;

    }
    if(email !== "" && email.includes('@') !== true ){
        setEmailError("Insira um email válido.");;
        isValid = false;
    }
    return isValid;
  }

  const onChangeEmail = ( event :React.FormEvent<HTMLInputElement> ) => {
    setEmailError("");
    setEmail(event.currentTarget.value);  
  }

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement> ) =>  {
    evt.preventDefault();
    const isValid = validate();

    if(isValid){
      dispatch(passwordRecovery(email));
    }
  }

  const ColorButton = withStyles((theme: Theme) => ({
    root: {
      color: 'var(--text-primary-color)',
      backgroundColor: 'var(--secondary-color)',
      '&:hover': {
        backgroundColor: 'var(--secondary-color-default)',
      },
    },
  }))(Button);

  return(
    ( message !== '' && messageType === FeedbackType.Success )
    ? <Redirect to="/login"/>
    : (
      <div className={css(styles.container)}>
        <div className={css(styles.formArea)}>

          <h3 className={css(styles.title, styles.fontDefault)}>Olá, recupere seu cadastro</h3>
          <h5 className={css(styles.title, styles.fontDefault)}>
            {"Informe seu email de acesso. Será enviado instruções para recuperação" 
            + " de senha para o endereço eletrônico informado."}</h5>

          <form onSubmit={( 
                e: React.ChangeEvent<HTMLFormElement>,
            ): void => handleSubmit(e)} >

            <TextField
              error = {emailError !== "" ? true: false }
              id={ emailError !== "" ? "outlined-error-helper-text" : "filled-basic" } 
              label= "Email"
              variant= "filled" 
              helperText= {emailError !== "" ? emailError : "" } 
              className={css(styles.textField, styles.fontDefault)}
              value={email}
              autoComplete="false"
              onChange = {( 
                  e: React.ChangeEvent<HTMLInputElement>,
              ): void => onChangeEmail(e)}
            />

            <ColorButton
              disabled={isFetching}
              type="submit"
              className={css(styles.buttonSave, styles.fontDefault)}
              variant="contained"
              color="primary">Enviar Instruções</ColorButton>
        </form>

        <h5 style={{fontWeight: 300, fontSize: '1em', color: '#FFF'}}className={css(styles.fontDefault)} >
          {"Obs.: É possível que o email seja direcionado para a caixa de spam," 
          + " não esqueca de verificar caso não encontre-o."}
        </h5>
      </div>
    </div>
    )
    
      
    );

  }

  export default RecoveryPassword;

  const styles = StyleSheet.create({

    fontDefault: {
      fontFamily: 'var(--font-primary) !important',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: '100vh',
      width: '100%',
      padding: '20px 0px',
      backgroundColor: 'var(--primary-color-default)'
    },
    textColorWhite : {
      color: 'var(--text-primary-color)',
      font: ''
    },

    textColorHover : {
      color: 'var(--surface-hover-color)',
    },

    title :{
      fontWeight: 'bold',
      color: 'var(--text-primary-color)',
      margin: '10px 0px',
    },
    label: {
      fontSize: '14px',
      color: 'var(--text-primary-color)',
      width: '100%',
      fontWeight: 'bold',
    },
    formArea: {
      width: '400px',
      height: '520px',
      margin: 'auto auto',
      padding:'20px 25px',
    },
    textField: {
      backgroundColor: 'var(--text-primary-color)',
      width: '100%',
      margin: '10px 0px',
    },

    buttonSave: {
      backgroundColor: 'var(--secondary-color-default)',
      color: 'var(--text-primary-color)',
      textShadow: 'var(--text-shadow-primary)',
      marginTop: '30px',
      marginBottom: '30px',
      cursor: 'pointer',
      width: '100%',
      "label":{
        fontFamily: 'var(--font-primary)',
      }
    },
   

  });
