import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { withStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState, FeedbackType } from '../../app-types';
import { registerUser } from '../../datastore/thunk-actions';
import { Redirect } from 'react-router-dom';
import { fetchGeneralConfigs } from '../../service/api-server-configuraton';
import { setMessage } from '../../datastore/generalActions';
import m from '../../app-types/messages';


function SignUp (){
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowRepeatPassword, setIsShowRepeatPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordRepeatError, setPasswordRepeatError] = useState("");
  const [username, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const isFetching = useSelector((state:ReduxState) => state.isFetching);
  const [message, messageType] = useSelector((state:ReduxState) => [state.feedback.message, state.feedback.type]);
  const dispatch = useDispatch();  

  useEffect(() => {
    fetchGeneralConfigs().then( config => {    
      if(config && config.create_account === false){
        dispatch(setMessage(m.user_registration_blocked, FeedbackType.Error))
      }
    })
  }, [dispatch])

  const validate = () => {
    let isValid: boolean = true;
    if(email === "" ){
      setEmailError("Campo Obrigatório.");
      isValid = false;
    }
    if(email !== "" && email.includes('@') !== true ){
      setEmailError("Insira um email válido.");
      isValid = false;
    }
    if( password === "" ){
      setPasswordError("Campo Obrigatório.");
      isValid = false;
    }
    if( repeatPassword === "" ){
      setPasswordRepeatError("Campo Obrigatório.");
      isValid = false;
    }
    if( username.length < 4 ) {
      setUserNameError("Deve ter no mínimo 4 caracteres");
      isValid = false;
    }    
    return isValid;
  }

  const onChangeEmail = ( event :React.FormEvent<HTMLInputElement> ) => {
    setEmailError("");
    setEmail(event.currentTarget.value);  
  }

  const onChangeUserName = ( event :React.FormEvent<HTMLInputElement> ) => {
    setUserNameError("");
    setUserName(event.currentTarget.value);  
  }

  const onChangePassword = ( event :React.FormEvent<HTMLInputElement> ) => {
    setPasswordError("");
    setPassword(event.currentTarget.value);  
  }

  const onChangeRepeatPassword = ( event :React.FormEvent<HTMLInputElement> ) => {
    setPasswordRepeatError("");
    setRepeatPassword(event.currentTarget.value);  
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement> ) =>  {
    evt.preventDefault();
    const isValid = validate();    
    if(isValid) dispatch(registerUser(username, email,password));
  }

  const ColorButton = withStyles((theme: Theme) => ({
    root: {
      color: 'var(--text-primary-color)',
      backgroundColor: 'var(--secondary-color)',
      '&:hover': {
        backgroundColor: 'var(--secondary-color-default)',
      },
    },
  }))(Button);

  return(
      ( message !== '' && messageType === FeedbackType.Success )
      ? <Redirect to="/login"/>
      : (
        <div className={css(styles.container)}>
          <div className={css(styles.formArea)}>
            <h4 className={css(styles.title, styles.fontDefault)}>
              Olá cadastre-se e não perca oportunidade de ganhar muitos prêmios
            </h4>      

            <form onSubmit={( 
                  e: React.ChangeEvent<HTMLFormElement>,
              ): void => handleSubmit(e)} >

              <TextField
                error = {userNameError !== "" ? true: false }
                id={ userNameError !== "" ? "outlined-error-helper-text" : "filled-basic" }
                label="Nome de usuário"
                variant="filled"
                className={css(styles.textField, styles.fontDefault)}
                value={username}
                helperText= {userNameError!== "" ? userNameError : "" } 
                /*autoComplete="username email"*/
                autoComplete="false"
                onChange = {( 
                    e: React.ChangeEvent<HTMLInputElement>,
                ): void => onChangeUserName(e)}
                />

              <TextField
                error = {emailError !== "" ? true: false }
                id={ emailError !== "" ? "outlined-error-helper-text" : "filled-basic" }
                label="Email"
                variant="filled"
                className={css(styles.textField, styles.fontDefault)}
                value={email}
                helperText= {emailError !== "" ? emailError : "" } 
                /*autoComplete="username email"*/
                autoComplete="false"
                onChange = {( 
                    e: React.ChangeEvent<HTMLInputElement>,
                ): void => onChangeEmail(e)}
              />

              <TextField 
                error = {passwordError!== "" ? true: false }
                id={ passwordError !== "" ? "filled-error" : "filled-basic" } 
                label="Senha"
                variant="filled"
                type= { isShowPassword ? "text" : "password"}
                helperText= {passwordError !== "" ? passwordError : "" } 
                autoComplete="current-password"
                value={password}
                className={css(styles.textField, styles.fontDefault)}
                onChange = {( 
                  e: React.ChangeEvent<HTMLInputElement>,
              ): void => onChangePassword(e)}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
              />

              <TextField 
                error = {passwordRepeatError !== "" ? true: false }
                id={ passwordRepeatError !== "" ? "filled-error" : "filled-basic" } 
                label="Repetir Senha"
                variant="filled"
                helperText= {passwordRepeatError !== "" ? passwordRepeatError : "" } 
                type={ isShowRepeatPassword ? "text" : "password"}
                autoComplete="current-password"
                value={repeatPassword}
                className={css(styles.textField, styles.fontDefault)}
                onChange = {( 
                  e: React.ChangeEvent<HTMLInputElement>,
              ): void => onChangeRepeatPassword(e)}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setIsShowRepeatPassword(!isShowRepeatPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {isShowRepeatPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
              />

              <ColorButton
                type="submit"
                disabled={isFetching}
                className={css(styles.buttonSave, styles.fontDefault)}
                variant="contained"
                color="primary">CADASTRAR</ColorButton>
            </form>
          </div>
        </div>
      )
    );
  }

  const styles = StyleSheet.create({
    fontDefault: {
      fontFamily: 'var(--font-primary) !important',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: '100vh',
      width: '100%',
      padding: '20px 0px',
      backgroundColor: 'var(--primary-color-default)',
    },
    textColorWhite : {
      color: 'var(--text-primary-color)',
    },

    textColorHover : {
      color: 'var(--surface-hover-color)',
    },

    title :{
      fontWeight: 'bold',
      color: 'var(--text-primary-color)',
      margin: '10px 0px',
    },
    label: {
      fontSize: '14px',
      color: 'var(--text-primary-color)',
      width: '100%',
      fontWeight: 'bold',
    },
    formArea: {
      width: '400px',
      height: '520px',
      margin: 'auto auto',
      padding:'20px 25px',
    },
    textField: {
      backgroundColor: 'var(--text-primary-color)',
      width: '100%',
      margin: '10px 0px',
    },
    buttonSave: {
      backgroundColor: 'var(--secondary-color-default)',
      color: 'var(--text-primary-color)',
      marginTop: '30px',
      marginBottom: '30px',
      cursor: 'pointer',
      width: '100%',
    },
    esqueceuSenha : {
      margin:'0 auto',
      width: '100%',
    },
    toggleExibirPassword : {
      float: 'right',
    },
    containerLinks: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
  });

  export default SignUp;