import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';



const element =
    <div style={{height: '100%', width: '100%', backgroundImage: 'var(--gradient-background'}}>
      <App />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    </div>;

ReactDOM.render(
  element,
  document.getElementById('root')
);

