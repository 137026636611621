
import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState, Answer } from '../../app-types';
import Chip from '@material-ui/core/Chip';
import { fetchAnswers, fetchAnswerFeedback } from '../../datastore/thunk-actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EmptyArea from '../Components/Uteis/EmptyArea';
import AreaFeedback from '../Components/Feedback';

const MIN_WAIT_TIME = 10000;

function IdeiasTabs () {  
  const isFetching = useSelector((state:ReduxState) => state.isFetching);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const answers = useSelector((state:ReduxState) => [...state.answers, ...state.answersFeedback]);
  const loadedCount = useSelector((state:ReduxState) => state.answers.length);
  const syncAnswers = useSelector((state:ReduxState) => state.syncTracker.answers);
  const syncFeedback = useSelector((state:ReduxState) => state.syncTracker.answersFeedback);

  useEffect(() => {
    if( syncAnswers + MIN_WAIT_TIME < Date.now()){ dispatch(fetchAnswers(0, false))}
  },[syncAnswers, dispatch]);

  useEffect(() => {    
    if( syncFeedback + MIN_WAIT_TIME < Date.now()){ dispatch(fetchAnswerFeedback())}
  },[syncFeedback, dispatch]);

  const filteredAnswers:Answer[] = answers.filter((answer) => {
    switch (tabIndex) {
      case 1: 
        return answer.is_pending_evaluation === true
      case 2:  
      return answer.allow_edit === true
      default:
        return true;
    }
  });

  const loadMore = () => {
    dispatch(fetchAnswers(loadedCount, true));
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setTabIndex(newValue);
  };
  
  return(
    <div className={css(styles.container)}>
      <Tabs 
        className={css(styles.tabs)}
        value={tabIndex} 
        onChange={handleChange} 
        variant='fullWidth'
        aria-label="Tabs de respostas">

        <Tab label="Todas" />
        <Tab label="Em Avaliação" />
        <Tab label="Melhorias" />
      </Tabs>

      <div className={ css( filteredAnswers.length === 0 ? styles.cardsEmpty : styles.cards )} >
        {
          isFetching && filteredAnswers.length === 0
          ? <div className={ css( styles.loading )} ><CircularProgress color="secondary" /></div>
          : (
            filteredAnswers.length === 0
            ? (
              <EmptyArea text="Nenhuma Sugestão Encontrada"/>
            )
            : (
              filteredAnswers.map((answer, key) => (
                <div key={key} className={css(styles.card)}  >
                  <AnswerCard answer={answer} />
                </div>
              ))            
            )
          )
        }

        {
          isFetching && filteredAnswers.length === 0
          ? null
          :(
            <div className={ css( styles.chips )} style={{marginTop: '0.5em'}} >
              <Button 
                variant="contained" 
                disabled={isFetching}
                onClick={loadMore}
                color="secondary">
                  
                Carregar Mais
              </Button>
            </div>
          )
        }
      </div>        
    </div>    
    );    
  }

  export default IdeiasTabs;    
    
  const styles = StyleSheet.create({    
    container: {
      height: 'var(--content-height)',
      backgroundColor: 'var(--primary-color-dark)',
      overflow: 'auto',
    },
    
    tabs: {
      backgroundColor: 'var(--primary-color-light)',
      color: '#fff',
    },

    chips: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      '@media (min-width: 850px)': {
        width: '800px'
      }
    },

    cards: {
      padding: '1em',
      overflow: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
    },

    cardsEmpty: {
      padding: '1em',
      overflow: 'auto',
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap'
    },

    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    card: {
      width: 'calc(100% - 1em)',
      margin: '0 0.5em 1em 0.5em',
      '@media (min-width: 850px)': {
        width: '800px'
      }
    },

    noResultCard: {
      textAlign: 'center',
      '@media (min-width: 850px)': {
        width: '800px'
      }
  },
  areaAnswer:{
    padding: '0px 0px 0px 8px'

  },

  textItalic: {
    fontStyle: 'italic',
  },

  });
  
  const AnswerCard = ({answer}:{answer:Answer}) => {
    const history = useHistory();
    
    const {
      original_title, 
      details, 
      is_locked, 
      score, 
      is_pending_evaluation,
      feedback_message,
      allow_edit } = answer;

    const handlerClick = () => {
      if(!allow_edit) return;

      history.push(`/melhoria/${answer.id}`)
    }

    return(
      <Card>
        <CardActionArea onClick={handlerClick}>
          <CardContent>
            <Typography gutterBottom variant="subtitle2" component="p" color="textSecondary" >
              {original_title}
            </Typography>
            <div className={css(styles.areaAnswer)}>
              <Typography className={css(styles.textItalic)} variant="body2" component="p"  >
                {details}
              </Typography>
            </div>
            

           <AreaFeedback feedback_message = {feedback_message} isOutline={false} />
         
          </CardContent>

        </CardActionArea>

        <CardActions>
            <div className={ css( styles.chips )} >
              {
                is_locked 
                ? (
                  <Chip 
                    size="small" 
                    label={`${score} ${score === 1 ? 'ponto' : 'pontos'}`} 
                    color="primary" />) 
                : null                
              }
              {
                is_pending_evaluation
                ? <Chip variant="outlined" size="small" label="Em avaliação" color="primary"/>
                : null
              }
              {
                allow_edit
                ? < Chip size="small" label="Precisa de Melhoria" color="secondary" />
                : null
              }
            </div>
        </CardActions>
      </Card>
    );
  }


  
  
  
  