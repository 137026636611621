import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  TextField, 
  DialogActions, 
  Button
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../app-types';

const PasswordConfirmation : React.FunctionComponent<PasswordConfirmationProps> = ({
  show, title, cancelHandler, confirmHandler, children}) => {
  const isFetching = useSelector((state:ReduxState) => state.isFetching )
  const [inputValue, setInputValue] = useState('');
  const confirmCallback = () => {
    confirmHandler(inputValue);
    setInputValue('');
  }
  
  return (
    <Dialog open={show} onClose={cancelHandler} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle style={{textAlign: 'center'}} id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {children}
        </DialogContentText>
        <TextField
          autoFocus
          value={inputValue}
          onChange={ev=> setInputValue(ev.target.value)}
          margin="dense"
          id="password"
          label="Senha de Acesso"
          type="password"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelHandler} color="primary">
          Cancelar
        </Button>
        <Button onClick={confirmCallback} color="primary" disabled={isFetching}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export type PasswordConfirmationProps = {
  show: boolean,
  title: string,
  cancelHandler: () => void,
  confirmHandler: (password:string) => void 
  children?: React.ReactNode
}

export default PasswordConfirmation