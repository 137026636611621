import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { authUser } from '../../datastore/thunk-actions';
import {useDispatch, useSelector} from 'react-redux'
import { ReduxState } from '../../app-types';
import { Redirect } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo2.svg'

function Login (){
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRembemberMe] =useState(true);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const dispatch = useDispatch();
  const isFetching = useSelector(( state:ReduxState ) => state.isFetching);
  const isLoggedIn = useSelector(( state:ReduxState ) => state.token === '' ? false : true );
  

  const validate = () => {
    let isValid: boolean = true;

    if(email === "" ){
      setEmailError("Campo Obrigatório.");
      isValid = false;

    }
    if(email !== "" && email.includes('@') !== true ){
        setEmailError("Insira um email válido.");;
        isValid = false;
    }

    if( password === "" ){
        setPasswordError("Campo Obrigatório.");
        isValid = false;
    }
    return isValid;
  }

  const onChangeEmail = ( event :React.FormEvent<HTMLInputElement> ) => {
    setEmailError("");
    setEmail(event.currentTarget.value);  
  }

  const onChangePassword = ( event :React.FormEvent<HTMLInputElement> ) => {
    setPasswordError("");
    setPassword(event.currentTarget.value);  
  }

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement> ) =>  {
    evt.preventDefault();
    const isValid = validate();
  
    if(isValid) {
      dispatch(authUser(email, password, rememberMe));
    }
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };



  const GoldCheckbox = withStyles({
    root: {
      color: "var(--secondary-color-light)",
      '&$checked': {
        color: "var(--secondary-color-default)",
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" className={css(styles.checkboxRemember)} {...props} />);

  const ColorButton = withStyles((theme: Theme) => ({
    root: {
      color: 'var(--text-primary-color)',
      backgroundColor: 'var(--secondary-color)',
      '&:hover': {
        backgroundColor: 'var(--secondary-color-default)',
      },
    },
  }))(Button);

  return(
    isLoggedIn 
      ? <Redirect to={{ pathname: "/"}}/>
      : (        
      <div className={css(styles.container)}>
        <div className={css(styles.formArea)}>
        <img src={logo} className="App-logo" alt="logo" />

        <h3 className={css(styles.title, styles.fontDefault)}>Bem vindo</h3>
        <h5 className={css(styles.title, styles.fontDefault)}>Não perca a oportunidade. Entre para ficar por dentro das novidades e prêmios.</h5>

        <form onSubmit={( 
              e: React.ChangeEvent<HTMLFormElement>,
          ): void => handleSubmit(e)} >

        <TextField
          error = {emailError !== "" ? true: false }
          id={ emailError !== "" ? "outlined-error-helper-text" : "filled-basic" } 
          label= "Email"
          variant= "filled" 
          helperText= {emailError !== "" ? emailError : "" } 
          className={css(styles.textField, styles.fontDefault)}
          value={email}
          autoComplete="false"
          onChange = {( 
              e: React.ChangeEvent<HTMLInputElement>,
          ): void => onChangeEmail(e)}
        />

        <TextField 
          error = {passwordError!== "" ? true: false }
          id={ passwordError !== "" ? "outlined-error-helper-text" : "standard-adornment-password" } 
          label="Senha"
          variant="filled"
          helperText= {passwordError !== "" ? passwordError : "" } 
          type= { isShowPassword ? "text" : "password"}
          /* autoComplete="current-password"*/
          autoComplete="false"
          value={password}
          className={css(styles.textField, styles.fontDefault)}
          onChange = {( 
            e: React.ChangeEvent<HTMLInputElement>,
        ): void => onChangePassword(e)  }    
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsShowPassword(!isShowPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {isShowPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
          }}    
        />

        <div className={css(styles.containerLinks, styles.fontDefault)}>
          
          <div className={css(styles.contanerRemember)}>
            <FormControlLabel
              control = {
                <GoldCheckbox
                checked={rememberMe}
                onChange={ e => setRembemberMe(!rememberMe)}
                inputProps={{ 'aria-label': 'secondary checkbox'}}
                />
            }
              className={css(styles.linkLabel, styles.fontDefault)}
              label="Lembrar"
            />
          </div>
          <div className={css(styles.esqueceuSenha)}>
            <Link className={css(styles.linkLabel, styles.fontDefault)}
              to="/recuperar-senha">Esqueceu a senha?</Link>
          </div>
        
        </div>

        <ColorButton
          type="submit"
          disabled = {isFetching}
          className={css(styles.buttonSave, styles.fontDefault)}
          variant="contained"
          color="primary">ENTRAR</ColorButton>


          <div className={css(styles.cadastro)}>
            <Link className={css(styles.linkLabel, styles.fontDefault)}
              to="/cadastro">
                Ainda não possui conta? <span style={{color: 'var(--secondary-color)'}}>Cadastre aqui</span>
            </Link>
          </div>

      </form>

      </div>
      </div>
      )
    );

  }

  export default Login;

  const styles = StyleSheet.create({

    fontDefault: {
      fontFamily: 'var(--font-primary) !important',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: '100vh',
      width: '100%',
      padding: '20px 0px',
      backgroundColor: 'var(--primary-color-default)'
    },
    textColorWhite : {
      color: 'var(--text-primary-color)',
      font: ''
    },

    textColorHover : {
      color: 'var(--surface-hover-color)',
    },

    title :{
      fontWeight: 'bold',
      color: 'var(--text-primary-color)',
      margin: '10px 0px',
    },
    label: {
      fontSize: '14px',
      color: 'var(--text-primary-color)',
      width: '100%',
      fontWeight: 'bold',
    },
    formArea: {
      width: '400px',
      height: '520px',
      margin: 'auto auto',
      padding:'20px 25px',
    },
    textField: {
      backgroundColor: 'var(--text-primary-color)',
      width: '100%',
      margin: '10px 0px',
    },

    buttonSave: {
      backgroundColor: 'var(--secondary-color-default)',
      color: 'var(--text-primary-color)',
      textShadow: 'var(--text-shadow-primary)',
      marginTop: '30px',
      marginBottom: '30px',
      cursor: 'pointer',
      width: '100%',
      "label":{
        fontFamily: 'var(--font-primary)',
      }
    },
    checkboxRemember : {
      marginRight: '0px !important',
    },

    containerLinks: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    linkLabel: {
      lineHeight: '36px',
      color: 'var(--text-primary-color)',
      fontSize: '14px',
      width: '100%',
      fontWeight: 'bold',
      marginRight: '0px !important',
      textDecoration: 'none'
      
    },
    esqueceuSenha: {
      display: 'flex',
      justifyContent: 'flex-end'
    },

    cadastro: {
      display: 'flex',
      justifyContent: 'center'
    },
    
    contanerRemember : {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center'
    }


  });
