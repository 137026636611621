import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import appReducers from './reducers';

const createReduxStore = ( initialState: object = {}) => (
  createStore(
    appReducers,
    initialState,
    ( 
      process.env.NODE_ENV === 'development'
        ? composeWithDevTools(applyMiddleware(thunkMiddleware))
        : applyMiddleware( thunkMiddleware )
    )
  )
);

export default createReduxStore;