
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import CircularProgress from '@material-ui/core/CircularProgress';



const CircleLoading = () => {

  return(
    <div className={ css( styles.loading )} >
      <CircularProgress color="secondary" />
    </div>
  );

}

export default CircleLoading;

const styles = StyleSheet.create({

  loading: {
    heigth: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

  
