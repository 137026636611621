import React, { useState} from 'react';
import { StyleSheet, css } from 'aphrodite';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HamburguerMenu from './MenuMobile';
import Chip from '@material-ui/core/Chip';
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from '../../app-types';
import { logout } from '../../datastore/thunk-actions'



function NavBarMain (){

  const balance = useSelector((state:ReduxState) => state.userData.balance);
  const username = useSelector((state: ReduxState) => state.userData.name);
  const [currentPage, setCurrentPage] = useState(useLocation().pathname);
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();

  const handleNavigator = (redirectTo : string) => {

    history.push(redirectTo);
    
  }

  React.useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);


  return(
    <div className={css(styles.gridContent)}>
      <div className={css(styles.gridItem)}>
        <div>
          <AppBar position='static' className={css(styles.appBar)}>
            <Toolbar>
              
              <div className={ css(styles.sectionDesktop)}>
                <Typography   
                  className={css(styles.title)}
                  variant='h6'
                  onClick={() => handleNavigator('/home')} 
                  noWrap>InovaPrime</Typography>

                <Chip className={css(styles.labelPontos)} 
                    label={balance === 1 ? '1 ponto':`${balance} pontos`} 
                    variant="outlined" />

                <div className={css(styles.subsectionDesktop)}>
                  <NavLink 
                    to='/minhas-ideias'
                    className={css(styles.navItem, 
                      currentPage === '/minhas-ideias' ? styles.menuActive : styles.menuDisable ) }
                    >Minhas Ideias</NavLink>


                  <NavLink 
                    to='/solicitacoes'
                    className={css(styles.navItem, 
                      currentPage === '/solicitacoes' ? styles.menuActive : styles.menuDisable) }
                    >Solicitações</NavLink>

                  <NavLink 
                    to='/premios'
                    className={css(styles.navItem, 
                      currentPage === '/premios' ? styles.menuActive : styles.menuDisable) }
                    >Premiações</NavLink> 

                  <NavLink 
                    to='/regulamento'
                    className={css(styles.navItem, 
                      currentPage === '/regulamento' ? styles.menuActive : styles.menuDisable) }
                    >Regulamento
                  </NavLink> 


                  {/* { username !== ""? */}
                    <div className={css(styles.contUsername)}>
                        <Typography className={css(styles.username)}>{`Olá, ${username}`}</Typography>
                        <div 
                          className={css(styles.navItem) }
                          onClick={() => dispatch(logout())}
                        >Sair</div>
                    </div>
                </div>
              </div>

              <div className={css(styles.sectionMobile)}>
                <HamburguerMenu/>
              <div className={css(styles.title)}>

              <Typography variant='h6' noWrap>InovaPrime</Typography>

            </div>
            
            <Chip 
              className={css(styles.labelPontos)} 
              label={balance === 1 ? '1 ponto':`${balance} pontos`}
              variant="outlined" 
            />
          </div>
        </Toolbar>
      </AppBar>
      </div>
    </div>
  </div>
    
    );
    
  }


export default NavBarMain;    
    
const styles = StyleSheet.create({

  title: {
    display: 'flex',
    flex: '1',
  },

  appBar: {
    backgroundColor: 'var(--primary-color-default)',
  },
  contUsername: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '20px'
  },
  username:{
    color: 'var(--secondary-color)',
    width: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  menuActive: {
    borderBottom : '3px solid var(--secondary-color)' ,
  },
  menuDisable: {
    borderBottom: 'var(--primary-color-default) solid 3px',
  },
  navItem: {
    float: 'left',
    display: 'block',
    color:' #f2f2f2',
    textAlign: 'center',
    margin: '0px 10px',
    padding: '0px',
    textDecoration: 'none',
    fontSize: '17px',
    borderBottom: 'transparent solid 3px',
    transition: 'border-bottom 0.15s',

    ':hover': {
      borderBottom: 'var(--secondary-color) solid 3px',
      cursor: 'pointer'
    },
  },
  gridContent:{
    display: 'grid',
    position: 'fixed',
    top: 0, left: 0, right: 0,
    zIndex: 10,
    backgroundColor: 'var(--primary-color-dark)',
    minHeight: '56px',
  },
  gridItem: {
    gridTemplateColumns: '1fr 2fr 1fr 1fr'
  },
  iconAccountUser:{
    margin: '0px 0px 0px 10px',
    color: 'var(--main-bg-color-white)',
    width: '24px',
    height: '24px'

  },
  labelPontos:{
    color: 'var(--secondary-color)',
    borderColor: 'var(--secondary-color)',
    margin: '0px 10px 0px 10px'
  },
  sectionDesktop: {
    display: 'none',

    '@media only screen and (min-width: 900px)':{
      display: 'flex',
      width: '100%',
      alignItems:'center',
      justifyContent: 'space-between',
    }
  },
  subsectionDesktop:{
    display: 'none',

    '@media only screen and (min-width: 900px)':{
      display: 'flex',
      alignItems:'center',
      justifyContent: 'space-between',
    }
  },
  sectionMobile: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'space-between',
    width: '100%',

    '@media only screen and (min-width: 900px)':{
      display: 'none',
    }
  },
  
});

  