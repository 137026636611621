import React, {useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ReduxState, Transaction } from '../../app-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EmptyArea from './Uteis/EmptyArea';
import TransactionCard from './TransactionCard';
import Button from '@material-ui/core/Button';
import CircleLoading from './Uteis/CircleLoading';
import { fetchTransactions, cancelTransaction } from '../../datastore/thunk-actions';
import { useDispatch, useSelector } from 'react-redux';
import PasswordConfirmation from './PasswordConfirmation';

function TransactionTabs (){ 
  const isFetching = useSelector((state:ReduxState) => state.isFetching);
  const transactions = useSelector((state:ReduxState) => state.transactions);
  const [tabIndex, setTabIndex] = useState(0);
  const [cancelId, setCancelId] = useState(0);
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const loadedCount = useSelector((state:ReduxState) => state.transactions.length);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const loadMore = () => {
    dispatch(fetchTransactions(loadedCount, true))
  }

  useEffect(() => {
    dispatch(fetchTransactions(0, false))
  }, [dispatch])

  const filteredTransactions:Transaction[] = transactions.filter(( item ) => {
    switch (tabIndex) {
      case 0: 
        return !item.is_complete && item.canceled_at === null;
      case 1: 
          return item.is_complete && item.canceled_at === null;
      case 2:  
        return item.canceled_at !== null;
      default: 
        return item;
    }
  });

  const handleCancelTransaction = (id: number, description: string) => {
    setDescription(description);

    setCancelId(id);
  }

  const closeDialog = () => {
    setCancelId(0);
    setDescription('');
  }

  const confirmHandler = (password:string) => {
    dispatch(cancelTransaction(cancelId, password));
    setCancelId(0);
  }
  
  return( 
    <div>
      <Tabs 
      className={css(styles.tabs)}
      value={tabIndex} 
      onChange={handleChange} 
      variant='fullWidth'
      aria-label="Tabs de respostas">

        <Tab label="Em Aberto" />
        <Tab label="Concluído" />
        <Tab label="Cancelado" />
      </Tabs>
    <div className={css(styles.container)}>      
      {
        isFetching && filteredTransactions.length === 0 
        ? <CircleLoading/> 
        : (
            filteredTransactions.length === 0 
            ? <EmptyArea text="Não existe solicitações no momento." />
            : (
              <div className={css(styles.cards )}>
                { filteredTransactions.map((transaction, key) => 
                  <div key={key} className={css(styles.card)} >
                    <TransactionCard 
                      transaction={transaction} 
                      onCancel={handleCancelTransaction} 
                    />
                  </div>
                )
              }
            </div>
            )
        )  
    }
    {
      isFetching && filteredTransactions.length === 0 
      ? null
      :(
        <div className={ css( styles.chips )} style={{marginTop: '0.5em'}} >
          <Button 
            variant="contained" 
            disabled={isFetching}
            onClick={loadMore}
            color="secondary">
            Carregar Mais
          </Button>
        </div>
      )   
    }     
    </div>
    <PasswordConfirmation 
        show={cancelId !== 0}
        title="Confirme sua Senha"
        cancelHandler={closeDialog}
        confirmHandler={confirmHandler}>
        
        <strong>
          Cancelamento de Solicitação
        </strong>
        <br/>
        {description}
      </PasswordConfirmation>
    </div>              
    );
}

export default TransactionTabs;    

const styles = StyleSheet.create({
  container: {
    height: 'var(--content-height)',
    backgroundColor: 'var(--primary-color-dark)',
    overflow: 'auto',
    padding: '1em',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabs: {
    backgroundColor: 'var(--primary-color-light)',
    color: '#fff',
  },
  cards: {
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  
  card: {
    margin: '0 0.5em 1em 0.5em',
    width: '100%',
    '@media (min-width: 800px)': {
      width: 'calc(100%/2 - 1em)'
    },
    '@media (min-width: 1200px)': {
      width: 'calc(100%/3 - 1em)'
    }
  },
  chips: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
});
