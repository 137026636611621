export default {
    START_FETCH: 'START_FETCH',
    CANCEL_FETCH: 'CANCEL_FETCH',
    
    START_BACKGROUND_FETCHING: 'START_BACKGROUND_FETCHING',
    CANCEL_BACKGROUND_FETCHING: 'CANCEL_BACKGROUND_FETCHING',

    SET_PRIZES: 'SET_PRIZES',
    APPEND_PRIZES: 'APPEND_PRIZES',

    SET_TOKEN: 'SET_TOKEN',

    SET_QUESTIONS: 'SET_QUESTIONS',
    CLEAR_QUESTIONS: 'CLEAR_QUESTIONS',
    
    SAVE_DRAFT: 'SAVE_DRAFT',
    CLEAR_DRAFT: 'CLEAR_DRAFT',
    PURGE_DRAFTS: 'PURGE_DRAFTS',
    LOAD_DRAFTS: 'LOAD_DRAFTS',
    PERSIST_DRAFTS: 'PERSIST_DRAFTS',

    SET_MESSAGE: 'SET_MESSAGE',
    CLEAR_MESSAGE: 'CLEAR_MESSAGE',

    SET_USER_DATA: 'SET_USER_DATA',

    SET_ANSWERS: 'SET_ANSWERS',
    SET_ANSWERS_FEEDBACK: 'SET_ANSWERS_FEEDBACK',
    CLEAR_ANSWERS_FEEDBACK: 'CLEAR_ANSWERS_FEEDBACK',
    APPEND_ANSWERS: 'APPEND_ANSWERS',

    FEEDBACK_CREATED_OFFLINE: 'FEEDBACK_CREATED_OFFLINE',

    SET_TRANSACTIONS:'SET_TRANSACTIONS',
    APPEND_TRANSACTIONS: 'APPEND_TRANSACTIONS',
    CLEAR_TRANSACTION: 'CLEAR_TRANSACTION',

    LOGOUT: 'LOGOUT'
}