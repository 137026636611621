import React, { useState } from 'react';
//import moment from "moment"; 
import { StyleSheet, css } from 'aphrodite/no-important';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ControllerPwa () {

  const [isShowModal, setIsShowModal] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
 
  //antes de instalar prompt
  window.addEventListener('beforeinstallprompt', (event) => {

  event.preventDefault();

  // if(checkDeviceMobile()){
    
  //   setDeferredPrompt(event);

    //setIsShowModal(true);

  // }
  
  });

 
/*
  window.addEventListener('DOMContentLoaded', () => {
    //verifica por onde o foi iniciado, se foi por browser ou standalone
    let displayMode = 'browser tab';
    if (navigator.standalone) {
      displayMode = 'standalone-ios';
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
      displayMode = 'standalone';
    }

    console.log('DISPLAY_MODE_LAUNCH:', displayMode);
  });*/

  const handleClickAccept = () => {

    deferredPrompt.prompt();

    setIsShowModal(false);
  
    //Limpa os dados do prompt
    setDeferredPrompt(null);
    window.deferedPrompt = null;

  }
  const handleClickclose = () => {
    
    setIsShowModal(false);

    //Limpa os dados do prompt
    setDeferredPrompt(null);
    window.deferedPrompt = null;
  }

  return( 
    <div>
      <Dialog
        open={isShowModal}
        onClose={handleClickclose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={css(styles.title)} id="alert-dialog-title">{"InovaPrime"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Aumente suas chances de ganhar prêmios</DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            onClick={handleClickclose}
            className={css(styles.Cancel)}
            color="primary"
          >Cancelar</Button>
        <Button
            onClick={handleClickAccept}
            color="primary"
            className={css(styles.btnAccept)}
            autoFocus
          >Instalar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}



const styles = StyleSheet.create({

  textColor: {
    color: 'var(--text-primary-color)'
  },
  title:{
    color: 'var(--primary-color-default)'
  },
  Cancel:{
    color: 'var(--surface-enabled-color)'
  },
  btnAccept:{
    backgroundColor: 'var(--secondary-color)',
    color: 'var(--text-primary-color)',
    padding: '0px 10px 0pxx 10px',

    ':hover': {
      backgroundColor: 'var(--secondary-color-light)',
      color: 'var(--text-primary-color)',
    },
  }
      

});


//  function checkPrompt() {
//   const today = moment().toDate();
//   const lastPrompt = moment(localStorage.getItem("installPrompt"));
//   const days = moment(today).diff(lastPrompt, "days");
//   //verificando as informações de timestamp é possivel checar se deve ser 
//   //enviado uma notificação para instalar pwa
//   const prompt = (isNaN(days) || days > 30) && checkIsIOS() && checkIsSafari;
//     if (prompt && "localStorage" in window) {
//         localStorage.setItem("installPrompt", today);
//     }

//     return prompt;
// }

/*
function IsCheckPromptPwa() {;
    const [isPrompt, setIsPrompt] = useState(false);
   // const [isIOS, setIsIOS] = useState({});

    useEffect(() => {
        setIsPrompt(checkPrompt());
        return() => console.log("CLEANUP INSTALL PROMPT", isPrompt);
    }, []);

    return isPrompt;
}
*/


// function checkIsIOS() {
//   //retorna qual o userAgent ativo no momento
//   const ua = window.navigator.userAgent;
//   const isIPad = !!ua.match(/iPad/i);
//   const isIPhone = !!ua.match(/iPhone/i)
//   return isIPad || isIPhone;
// }

// function checkIsSafari() {
//   //retorna qual o userAgent ativo no momento
//   const ua = window.navigator.userAgent;
//   const webkit = !!ua.match(/webkit/i);
//   const isIPad = !!ua.match(/iPad/i);
//   const isIPhone = !!ua.match(/iPhone/i)
//   const isIOS = isIPad || isIPhone;
//   return isIOS && webkit && !ua.match(/CriOS/i); 
// }

// function checkModeStandAlone(){
//   //checa se o visitante esta usando mobile, ou safari browseer ou ja tem 
//   //o pwa instalado.

//   if (navigator.standalone) {
//     return false;
//   }
//   return true;
// }