import React, { useState } from 'react';
import { IPrize, ReduxState } from '../../app-types'
import { StyleSheet, css } from 'aphrodite/no-important';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';


function PrizeItem ({prize, handleSubmit}:
  {prize: IPrize, handleSubmit: (prizeId:number,prizeDescription:string) => void}){
  
  const [isShowingActions, setShowActions] = useState(false);
  const hasBalance = useSelector((state:ReduxState) => state.userData.balance >= prize.value );
  const isFetching = useSelector((state:ReduxState) => state.isFetching);

  const submitHandler = () => {
    handleSubmit(prize.id, `${prize.description}(${prize.value} pontos)`);
  }

  return(
    <div className={css(styles.containerCard)} onClick={() => isShowingActions ? '' : setShowActions(true)}>
      { 
        prize.media_url === null ? 
          <div className={css(styles.emptyImage)}>Sem Foto</div> : 
          <div 
            className={css(styles.pictureArea)} 
            style={{backgroundImage: `url(${prize.media_url })`}} 
          />
      }

      <div className={css(styles.description)}>{prize.description}</div>

      <div className={css(styles.value)}>{prize.value} Pontos</div>

      <div className={css(styles.actions, isShowingActions && styles.active)}>
      <div className={css(styles.contHidePanel)}>
        <IconButton 
          aria-label="fechar" 
          onClick={() => { setShowActions(false);}}>

          <CloseIcon />
        </IconButton>
      </div>

      <div className={css(styles.contSubmit)}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={submitHandler}
          disabled={!hasBalance || isFetching}>
          {
            hasBalance
              ? 'Solicitar Troca!'
              : 'Saldo Insuficiente'
          }
        </Button>
      </div>
      </div>
    </div>    
    );
    
  }
    
  const styles = StyleSheet.create({    
    containerCard: {
      height: '320px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '.5em',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
      transition: '0.3s',
      borderRadius: '5px',
      margin: '0 0.5em 1em 0.5em',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: 'var(--main-bg-color-white)',
      ':last-child': {
        marginBottom: 0,
      },
      ':hover': {
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
      },
      '@media (min-width: 800px)': {
        width: 'calc(100%/2 - 1em)'
      },
      '@media (min-width: 1200px)': {
        width: 'calc(100%/3 - 1em)'
      }
    },
    actions: {
      position: 'absolute',
      backgroundColor: 'var(--secondary-color-opacity)',
      top: 0, left: 0, right: 0, bottom: 0,
      transform: 'translate(0%, 150%)',
      transition: 'all 300ms ease-in-out',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'flex-end',
      justifyContent: 'space-between'
    },

    contHidePanel: {
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },

    contSubmit: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    active: {
      transform: 'translate(0%, 0%)',
    },

    pictureArea: {
      borderRadius: '5px 5px 0 0',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',      
      height: '230px',
      width: '100%',
    },
    emptyImage:{
      borderRadius: '5px 5px 0 0',
      backgroundColor: 'var(--surface-focused-color)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'var(--surface-hover-color)',
      height: '12em',
      width: '100%',
    },
    description: {
      fontFamily: "Roboto, san-serif, Work Sans",
      color: 'var(--primary-color-default)',
      fontWeight: 'bold',
      margin: '12px 0px',
    },
    value: {
      color: 'var(--primary-color-default)',
      fontWeight: 600,
      fontFamily: "Roboto, san-serif, Work Sans",
      margin: '0px 0px 8px 0px',
    },   
  });
  
  export default PrizeItem;     