import { SERVER_URL } from '../app-types/constants';

export type  ServerConfig = {
  create_account: boolean
}

const fetchGeneralConfigs = () : Promise<ServerConfig | null> => {
  let serverConfig : ServerConfig = {create_account: true};

  return new Promise((resolve) => {
    if(! navigator.onLine) resolve( null );

    fetch(`${SERVER_URL}/api/v1/app_configurations`)
    .then( async (response) => {
      if( response.ok ){
        const jsonData = await response.json();
        serverConfig.create_account = jsonData.create_account;
        resolve(serverConfig);
      }
    })
    .catch(() => resolve(null));
  })
}

const fetchRules = ( token:string ) : Promise<string> => {
  let rules_url = '';

  return new Promise((resolve) => {
    if(! navigator.onLine) resolve('');

    fetch(`${SERVER_URL}/api/v1/app_configurations/rules`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then( async (response) => {
      if( response.ok ){
        const jsonData = await response.json();
        rules_url = jsonData.file_url;
        resolve(rules_url);
      }
    })
    .catch(() => resolve(''));
  })
}

export {
  fetchGeneralConfigs,
  fetchRules
}