import React, { ReactNode, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from './app-types';
import { fetchUserData, fetchQuestions } from './datastore/thunk-actions';


type Props = {
  children:  ReactNode
}

const ProtectedRoute = ({ children, ...rest }:Props) => {
  const token = useSelector(( state:ReduxState ) => state.token )
  const dispatch = useDispatch();  

  useEffect(() => {
    if(token){
      dispatch(fetchUserData());
      dispatch(fetchQuestions());
    }
  })

  return (
    <Route 
      {...rest}
      render={({ location }) => (
        token !== ''
          ? children 
          : <Redirect to={{ pathname: "login",state: { from: location} }}/>
      )}
    />
  )
}

export default ProtectedRoute;