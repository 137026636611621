import React from 'react';
import { Provider } from 'react-redux';
import createReduxStore from './datastore';
import  Prizes from './ui/Components/Prizes';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';
import { HashRouter, Switch, Route } from "react-router-dom";
import SignIn from './ui/Credentials/Login';
import ProtectedRoute from './ProtectedRoute';
import { draftsSubscription } from './datastore/localStorage';
import { getDrafts } from './datastore/localStorage';
import NavBarMain from './ui/Menu/NavBarMain';
import IdeiasTabs from './ui/Components/IdeiasTabs';
import FormAnswer from './ui/Components/FormAnswer';
import Voucher from './ui/Components/Voucher';
import FeedBack from './ui/Feedback';
import QuestionsList from  './ui/Components/QuestionsList';
import ControllerPwa from './Pwa/ControllerPwa';
import Login from './ui/Credentials/SignUp';
import RecoveryPassword from './ui/Credentials/RecoveryPassword';
import TransactionTabs from './ui/Components/TransactionTabs';
import Rules from './ui/Components/Rules';

const token = localStorage.getItem('auth_key') || ''
const drafts = getDrafts();
const store = createReduxStore({token, drafts });
store.subscribe(() => draftsSubscription(store));

function App() {
  
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ControllerPwa/>
        <HashRouter>
          <Switch>
            <Route path="/login">
              <SignIn/>
            </Route>

            <Route path="/cadastro">
              <Login/>
            </Route>

            <Route path="/recuperar-senha">
              <RecoveryPassword/>
            </Route>

            <ProtectedRoute>
              <NavBarMain />

              <div id="app-content">
                <Route exact path="/">
                  <QuestionsList/>
                </Route>

                <Route exact path="/home"> 
                  <QuestionsList/>
                  
                </Route>
                
                <Route path="/premios">
                  <Prizes/>
                </Route> 

                <Route path="/minhas-ideias">
                  <IdeiasTabs/>
                </Route>

                <Route path="/questao/:id">
                  <FormAnswer />
                </Route>

                <Route path="/melhoria/:id">
                  <FormAnswer />
                </Route>

                <Route path="/voucher/:id">
                  <Voucher />
                </Route>

                <Route path="/solicitacoes">
                  <TransactionTabs />
                </Route>

                <Route path="/regulamento">
                  <Rules />
                </Route>
              </div>
            </ProtectedRoute>
          </Switch>
        </HashRouter>      
        <FeedBack />
      </ThemeProvider>    
    </Provider>      
    );
  }
  
  export default App;
  
  

  const theme = createMuiTheme({
    palette: { 
      primary: { main: '#004F71' },
      secondary: { main: '#debb55' }
    },
  })