import { 
  IReduxAction, 
  IPrize, 
  IQuestion, 
  IAnswerDraft,
  User,
  FeedbackType,
  Answer,
  Transaction
} from '../app-types';
import C from '../app-types/action-types';

/**
* action creator for the START_FETCH ReduxAction
*/
const fetching = () : IReduxAction => ({
  type: C.START_FETCH
});

/**
* action creator for the CANCEL_FETCH ReduxAction
*/
const cancelFetching = () : IReduxAction => ({
  type: C.CANCEL_FETCH
});

/**
 * action creator for the SET_PRIZES ReduxAction
 * @param payload list of prizes
 */
const setPrizes = (payload: IPrize[] ) : IReduxAction => ({
  type: C.SET_PRIZES,
  payload: payload
});

/**
 * action creator for the APPEND_PRIZES ReduxAction
 * @param payload list of new prizes
 */
const appendPrizes = (payload: IPrize[] ) : IReduxAction => ({
  type: C.APPEND_PRIZES,
  payload: payload
});

/**
 * action creator for the SET_TOKEN ReduxAction
 * @param payload new auth token 
 */
const setToken = ( payload: String) : IReduxAction => ({
  type: C.SET_TOKEN,
  payload
});

/**
 * action creator for the CLEAR_QUESTIONS ReduxAction
 */
const clearQuestions = () : IReduxAction => ({
  type: C.CLEAR_QUESTIONS
});

/**
 * action creator for the SET_QUESTION ReduxAction
 * @param payload list of questions
 */
const setQuestions = (payload: IQuestion[]) : IReduxAction => ({
  type: C.SET_QUESTIONS,
  payload
});

/**
 * action creator that saves drafts of suggestions
 * @param questionId question identifier
 * @param details details text
 */
const saveDraft = (questionId: number, details: String) : IReduxAction => ({
  type: C.SAVE_DRAFT,
  payload: { questionId, details }
});

/**
 * action creator to clear a specific draft
 * @param questionId question identifier that will be deleted
 */
const clearDraft = (questionId: number) => ({
  type: C.CLEAR_DRAFT,
  payload: { questionId }
});

/** action creator that will DELETE ALL DRAFTS */
const purgeDraft = () => ({ type: C.PURGE_DRAFTS })

/** action creator to identify that drafts were just persisted */
const persistDrafts = () => ({type: C.PERSIST_DRAFTS})

/**
 * action creator to load a list of drafts to the redux store
 * @param drafts list of answer drafts or empty array
 */
const loadDrafts = (drafts: IAnswerDraft[]) : IReduxAction => ({
  type: C.LOAD_DRAFTS,
  payload: drafts
});

/**
 * action creator for the 'SET_USER_DATA'
 * @param user user data to be loaded in the reduxstore
 */
const setUserData = (user:User):IReduxAction => ({
  type: C.SET_USER_DATA,
  payload: user
});

const setMessage = (message:string, type:FeedbackType):IReduxAction => ({
  type: C.SET_MESSAGE,
  payload: { message, type}
});

const clearMessage = () : IReduxAction => ({type: C.CLEAR_MESSAGE});

const setAnswers = (answers:Answer[]) : IReduxAction => ({
  type: C.SET_ANSWERS,
  payload: answers
})

const appendAnswers = (answers:Answer[]) : IReduxAction => ({
  type: C.APPEND_ANSWERS,
  payload: answers
})

const doLogout = ():IReduxAction => ({ type: C.LOGOUT })

const setFeedBackAnswers = (answers:Answer[]):IReduxAction => ({
  type: C.SET_ANSWERS_FEEDBACK,
  payload: answers
})

const clearFeedbackAnswer = (answerId:number):IReduxAction => ({
  type: C.CLEAR_ANSWERS_FEEDBACK,
  payload: answerId
})

const startBackgroundFetching = ():IReduxAction => ({
  type: C.START_BACKGROUND_FETCHING
})

const cancelBackgroundFetching = ():IReduxAction => ({
  type: C.CANCEL_BACKGROUND_FETCHING
})

const setTransactions = (transactions:Transaction[]):IReduxAction => ({
  type: C.SET_TRANSACTIONS,
  payload: transactions
})

const appendTransactions = (transactions:Transaction[]):IReduxAction => ({
  type: C.APPEND_TRANSACTIONS,
  payload: transactions
})

const clearTransaction = (transactionId:number) : IReduxAction => ({
  type: C.CLEAR_TRANSACTION,
  payload: transactionId
})

const offlineFeedbackSalved = (answerId:number, details:string) : IReduxAction => ({
  type: C.FEEDBACK_CREATED_OFFLINE,
  payload: { id: answerId, details}
})

export {
  doLogout,
  appendPrizes,
  cancelFetching,
  fetching,
  setPrizes,
  setToken,
  clearQuestions,
  setQuestions,
  saveDraft,
  clearDraft,
  purgeDraft,
  loadDrafts,
  persistDrafts,
  setUserData,
  setMessage,
  clearMessage,
  setAnswers,
  appendAnswers,
  setFeedBackAnswers,
  clearFeedbackAnswer,
  startBackgroundFetching,
  cancelBackgroundFetching,
  setTransactions,
  appendTransactions,
  clearTransaction,
  offlineFeedbackSalved
}