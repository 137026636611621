import React, {useState, useEffect} from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { syncStashedAnswers } from '../../service/sync-strategy';
import { Button } from '@material-ui/core';

export default function CarouselTest(){
  const isMobile = useMediaQuery(
    "only screen and (min-device-width: 320px) and (max-device-height : 896px) and (orientation : portrait)");
  const isFetching = useSelector(( state) => state.syncTracker.question === 0);
  const questionsList = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  const [questionId, setQuestionId] = useState(0);
  let history = useHistory();
  
  const handleClickQuestion = () => {
    const id = questionsList[questionId].id;
    history.push(`/questao/${id}`);
  }

  useEffect(() => {
    if(navigator.onLine){
      dispatch(syncStashedAnswers());
    }
  }, [dispatch])
  
  return(

    <div className={css(styles.content)}>
      <div className={css(styles.textQuote)} >
        <Typography 
          variant="body1" 
          display="block" 
          style={{
            color: 'var(--secondary-color)',
            display: 'flex',
            fontStyle: 'italic',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          gutterBottom
          >"Todo o conhecimento humano começou com intuições, passou daí aos conceitos e terminou com ideias." - Immanuel Kant
        </Typography>

      </div>
        
      {
        questionsList.length === 0 
        ? (
          <div style={{color: 'white', padding: '1em', display: 'flex', alignItems: 'center'}}>
            {
              isFetching
              ? <CircularProgress color="secondary" />
              : (
                <h3 style={{textAlign: 'center'}} >
                  Não há questões disponíveis no momento,
                   tente novamente mais tarde.
                </h3>            
              )
            }
          </div>
        )
        : (
          <AutoRotatingCarousel
          // label="Enviar Sugestão"
          open={true} 
          autoplay={questionsList.length > 1}
          interval = {10000}
          containerStyle={{zIndex: 1}}
          mobile={isMobile}
          onChange = {(index) => setQuestionId(index)}
          ButtonProps ={{ onClick:handleClickQuestion, color:'secondary'}}
          >
            { questionsList.map ((item, i) => 
              <Slide
              key={i}
                title={''}
                style = {{ backgroundColor: 'var(--primary-color-dark)'}}
                media={
                <div className={css(styles.imgFake)}>
                  <div style={{width:'100%'}}>
                    <Chip 
                    className={css(styles.labelPontos)} 
                    label={`Ganhe até ${item.max_score} pontos`}
                    variant="outlined" 
                    />
                  </div>
                  <div style={{display:'flex'}}>
                    <Typography 
                      className={css(styles.title)} 
                      variant="h6" component="h5"
                      >
                      {item.title}
                    </Typography>

                  </div>

                  {
                    item.category_description !== null ? 
                    <Typography 
                    className={css(styles.labelcategory)} 
                    variant="caption" 
                    >Categoria: {item.category_description}
                    </Typography>: ""
                    
                  }
                 
                 { item.published_until ? 
                  <div style={{ alignSelf: 'flex-end'}}>
                      <Typography 
                      className={css(styles.validade)} 
                      variant="caption" display="block" gutterBottom
                      >Sugestões até { new Date(item.published_until).toLocaleDateString('pt-BR')}  
                    </Typography>
                  </div>:""
                 }

                <p style={{color: 'white'}}>
                  {
                    item.answers_count === 0 
                    ? "Envie sua sugestão e ganhe prêmios..."
                    : ( item.answers_count === 1
                      ? `${item.answers_count} sugestão enviada`
                      : `${item.answers_count} sugestões enviadas`
                    )
                  }
                </p>                 
                 

                </div>}
                subtitle={
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Button 
                      onClick={handleClickQuestion}
                      variant="contained" 
                      color="secondary">

                      Enviar Sugestão
                    </Button>
                  </div>
                  }
                  description={item.description}
                  />)
                }
          </AutoRotatingCarousel>

)
}
    

  </div>
  );

}

const styles = StyleSheet.create({
  
  imgFake: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    display:'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
    background: 'var(--gradient-background-blue-dark)',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: 'var(--primary-color-dark)',
    height: '100%',
  },
  slideMedia: {
    background: 'var(--gradient-background)'
  },
  btnVerQuestao: {
    backgroundColor: 'var(--secondary-color)',
    color: 'var(--text-primary-color)'
  },
  title: {
    //font: 'var(--font-primary)',
    height: '100%',
    marginTop: '20px',
    color: 'var(--text-primary-color)',
    flex: 5,
    padding: '8px',
    justifyContent: 'center',
    //height: '70%',

  },
  labelPontos:{
    //backgroundColor: 'var(--secondary-color)',
    color: 'var(--secondary-color)',
    fontSize: '18px',
    borderColor: 'var(--secondary-color)',
    padding: '8px',
    margin: '20px 10px 0px 10px'
  },
  labelcategory:{
    color: 'var(--secondary-color)',
  },
  validade:{
    color: 'var(--text-primary-color)',
    fontSize: '10px',
    padding: '0px 8px 0px 0px'

  },
  textQuote: {
    display: 'none',

    '@media only screen and (min-width: 900px)':{
      color: 'var(--secondary-color)',
      height: '100px',
      width: '100%',
      display: 'grid !important',
      position: 'fixed',
      top: '40px',
      zIndex: '3 !important'
    }
    
   
  
  }

});

 