import React from 'react';
import { Transaction } from '../../app-types'
import { StyleSheet, css } from 'aphrodite/no-important';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';



const TransactionCard = ({
  transaction, onCancel}:
  {transaction:Transaction, onCancel:((id:number, description:string) => void)}) => {    
  const {
    id,
    description,
    value,
    is_complete,
    created_at,
    canceled_at
  } = transaction;
  let history = useHistory();

  const handlerCancelClick = () => {
    if(is_complete) return;
    onCancel(id, description);
  }

  const handlerGenerateVoucherClick = (id: number ) => {
    if(is_complete) return;
    if(id === null) return;
    history.push(`/voucher/${id}`)
  }

  return(
    <Card>
      <CardContent >
        <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
          <Chip 
            size="small" 
            label={`${Math.abs(value)} pontos`}
            style={{backgroundColor: 'var(--surface-error-color)', color:'var(--text-primary-color)' }}
          />
        </div>
          <Typography gutterBottom variant="subtitle1" component="h2">
            {description}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
          { 
            canceled_at !== null 
            ? `Cancelado em ${ new Date(canceled_at).toLocaleString('pt-BR')}`  
            : `Realizada em ${new Date(created_at).toLocaleString('pt-BR')}`
          }
          </Typography>
      </CardContent>
      <div>
        {
          transaction.canceled_at !== null 
          ? null 
          : (
            <div className={ css( styles.chips )} >
            {
              transaction.is_complete === false ?
              <div className={css(styles.areaButtons)}>
                <Button style={{color: '#b00020', margin: '0 20px 0 0'}} onClick={handlerCancelClick}>Cancelar</Button>
                {
                  transaction.voucher !== "" || transaction.voucher !== null 
                  ? (
                    <Button 
                      variant="text" 
                      color="primary" 
                      onClick={() => handlerGenerateVoucherClick(transaction.id)}>

                      Gerar Voucher
                    </Button>
                    )
                  : null
                }
              </div>
            : null
            }
            </div>
          )
        }
      </div>      
    </Card>
  );
}

export default TransactionCard;

const styles = StyleSheet.create({

  areaCard: {
    display: 'flex',
  },
  areaButtons: {
    display: 'flex',
    justifyContent: 'space-around',    
  },
  
  chips: {
    display: 'flex',
    padding: '8px',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
