import debounce from 'lodash/debounce';
import { ReduxState, IAnswerDraft, User, IQuestion, Answer, StashedAnswer } from '../../app-types';
import { CombinedState, Store } from "redux";
import { persistDrafts } from '../generalActions';

const DEBOUNCE_WAIT_TIME = 5000;
const PERSIST_WAIT_TIME = 5000;
const _ANSWERS_LS_KEY_ = 'answers_list';
const _DRAFTS_LS_KEY_ = 'draft_list';
const _QUESTION_LS_KEY_ = 'questions_list';
const _USER_LS_KEY_ = 'user_data';
const _ANSWER_FEEDBACK_LS_KEY_ = 'answers_feedback';
const _STASHED_ANSWERS_ = 'stashed_answers';

/**
 * debounced function to subscribe and persist the draft list
 * @param store.getState redux store store.getState function
 * @param dispatch redux dispatch function
 */
const draftsSubscription = debounce((
    store: Store<CombinedState<ReduxState>>
  ) => {
  
    if( !store.getState().persistanceTracker.drafts ){
    if(Date.now() > store.getState().syncTracker.drafts + PERSIST_WAIT_TIME){
      localStorage.setItem(_DRAFTS_LS_KEY_, JSON.stringify(store.getState().drafts));

      store.dispatch(persistDrafts());
    }
  }
}, DEBOUNCE_WAIT_TIME);

/**
 * This function will try to load drafts from localstorage, 
 * if none is found then will return an emtpy array
 */
const getDrafts = (): IAnswerDraft[] => {
  let parsedDrafts: IAnswerDraft[] = [];
  try{
    parsedDrafts = JSON.parse(localStorage.getItem(_DRAFTS_LS_KEY_) as string);
  }catch(e){}

  return parsedDrafts || []
}

//TODO purge store

/**
 * persist the user data in the localstorage
 * @param userData user data to be stored in the localstorage
 */
const persistUserData = (userData:User) => {
  localStorage.setItem(_USER_LS_KEY_, JSON.stringify(userData));
}
/**
 * 
 */
const getUserData = () : User => {
  const emptyUser:User = {
    name: '',
    balance: 0,
    answers: {
      feedback: 0,
      pending: 0,
      sent: 0,
    }
  };
  let parsedUserData:User = emptyUser;

  try {
    parsedUserData = JSON.parse(localStorage.getItem(_USER_LS_KEY_) as string)
  } catch (error) {}

  return parsedUserData || emptyUser;
}

/**
 * function to persist the question list in the localstorage
 * @param questions question list to be persisted
 */
const persistQuestions = (questions: IQuestion[]) => {
  localStorage.setItem(_QUESTION_LS_KEY_, JSON.stringify(questions));
}

/**
 * Recovers the question list from the localstorage
 */
const getQuestions = () : IQuestion[] => {
  let parsedData:IQuestion[] = [];

  try {
    parsedData = JSON.parse(localStorage.getItem(_QUESTION_LS_KEY_) as string);
  } catch (error) {}

  return parsedData || [];
}

/**
 * Purge all data store in the localstorage
 */
const purgePersistedData = () => {
  localStorage.clear();
}
/**
 * 
 * @param answers 
 */
const persistAnswers = (answers:Answer[])  => {
  localStorage.setItem(_ANSWERS_LS_KEY_, JSON.stringify(answers))
}
/**
 * 
 */
const getAnswers = ():Answer[] => {
  let parsedData:Answer[] = []

  try{
    parsedData = JSON.parse(localStorage.getItem(_ANSWERS_LS_KEY_) as string);
  } catch(error){}

  return parsedData || [];
}
/**
 * 
 * @param answers 
 */
const persistAnswersFeedback = (answers:Answer[]) => {
  localStorage.setItem(_ANSWER_FEEDBACK_LS_KEY_, JSON.stringify(answers));
}
/**
 * 
 */
const getAnswersFeedback = ():Answer[] => {
  let parsedData:Answer[] = [];

  try{
    parsedData = JSON.parse(localStorage.getItem(_ANSWER_FEEDBACK_LS_KEY_) as string);
  }catch(error){}
  
  return parsedData || [];
}
/**
 * 
 * @param answerToStash 
 */
const persistStashedAnswers = (answerToStash:StashedAnswer) => {
  let stashedAnswers = getStashedAnswers();

  localStorage.setItem(
    _STASHED_ANSWERS_,
    JSON.stringify([...stashedAnswers, answerToStash])
  )
}
/**
 * 
 */
const setStashedAnswers = (stashedAnswers:StashedAnswer[]) => {
  localStorage.setItem(
    _STASHED_ANSWERS_,
    JSON.stringify(stashedAnswers)
  )
}
/**
 * 
 */
const getStashedAnswers = ():StashedAnswer[] => {
  let parsedData:StashedAnswer[] = [];

  try{
    parsedData = JSON.parse(localStorage.getItem(_STASHED_ANSWERS_) as string)
  }catch(error){}

  return parsedData || []
}

export {
  persistUserData,
  getUserData,
  purgePersistedData,
  draftsSubscription,
  getDrafts,
  getQuestions,
  persistQuestions,
  persistAnswers,
  getAnswers,
  persistAnswersFeedback,
  getAnswersFeedback,
  persistStashedAnswers,
  setStashedAnswers,
  getStashedAnswers
}