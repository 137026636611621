import { ThunkAction } from "redux-thunk";
import { IReduxAction, ReduxState, FeedbackType, StashedAnswer } from '../app-types';
import { 
  fetching, 
  cancelFetching, 
  setPrizes, 
  setQuestions,
  setToken,
  clearDraft,
  setUserData,
  loadDrafts as AC_loadDrafts,
  setMessage,
  setAnswers,
  appendAnswers,
  doLogout,
  setFeedBackAnswers,
  clearFeedbackAnswer,
  setTransactions,
  appendTransactions,
  offlineFeedbackSalved
} from '../datastore/generalActions';
import { 
  getDrafts, 
  getUserData, 
  persistUserData, 
  purgePersistedData, 
  getQuestions, 
  persistQuestions, 
  persistAnswers, 
  getAnswers, 
  getAnswersFeedback, 
  persistAnswersFeedback,
  persistStashedAnswers} from './localStorage';
import t from '../app-types/messages';
import { MIN_RESYNC_TIME, SERVER_URL } from '../app-types/constants';

export type ThunkResult<R> = ThunkAction<R, ReduxState, undefined, IReduxAction>;

/**
 * 
 */
const fetchPrizes = () : ThunkResult<void> => (dispatch, getState) => {
  dispatch(fetching());
  fetch( `${SERVER_URL}/api/v1/prizes`, {
    headers: {
      'Authorization': `Bearer ${getState().token}`
    }
  })
  .then( async (response) => {
    if(![200, 401].includes(response.status)){
      throw new Error(response.statusText);
    }

    if(response.status === 401) dispatch(logout());

    if(response.status === 200){
      const jsonData = await response.json();
      dispatch(setPrizes(jsonData));
    }
    
    dispatch(cancelFetching())
  })
  .catch(() => dispatch(cancelFetching()));
}
/**
 * 
 */
const fetchQuestions = () : ThunkResult<void> => (dispatch, getState) => {
  if(Date.now() - MIN_RESYNC_TIME < getState().syncTracker.question) return;
  
  if( !navigator.onLine ){
    dispatch(setQuestions(getQuestions()));
    return;
  }

  fetch(`${SERVER_URL}/api/v1/questions`, {
    headers: { 'Authorization': `Bearer ${getState().token}`}
  })
  .then( data => data.json())
  .then( json => {
    const questions = json.map((question : any) => {      
      if(!question.published_until){
        return question
      }else{
        const dateComponents = question.published_until.split('/');
        const newDate = new Date(dateComponents[2], dateComponents[1] - 1, dateComponents[0])
        return Object.assign(question, { published_until: newDate})
      }
    });

    persistQuestions(questions)
    dispatch(setQuestions(questions))
  })
  .catch(e => e)
}
/**
 * 
 * @param email 
 * @param password 
 * @param rembemberMe 
 */
const authUser = (email: string , password: string, rembemberMe:boolean) : ThunkResult<void> => 
  (dispatch) => {

  dispatch(fetching());  
  fetch( `${SERVER_URL}/auth_user`, {
    method: "POST",
    body: JSON.stringify({
      "email" : email,
      "password": password
    })
  })
  .then( async response => {
    if(response.status !== 401 && response.status !== 200){
      throw new Error(response.statusText);
    }

    const jsonData = await response.json();

    if (response.ok) {
      dispatch(setToken(jsonData.token));
      purgePersistedData();
      if(rembemberMe) localStorage.setItem('auth_key', jsonData.token);
    } else {
      dispatch(setMessage(jsonData.errors.reason, FeedbackType.Error));
    }
    dispatch(cancelFetching());
  })
  .catch( () => {
    dispatch(cancelFetching());
    dispatch(setMessage(
      'Não foi possível acessar, tente novamente mais tarde', FeedbackType.Error));
  });
}
/**
 * 
 * @param name 
 * @param email 
 * @param password 
 */
const registerUser = (name:string, email:string, password: string ) : ThunkResult<void> => 
  (dispatch) => {
  
  if( !navigator.onLine ){
    dispatch(setMessage(t.not_online, FeedbackType.Error));
    return;
  }

  dispatch(fetching());

  fetch(`${SERVER_URL}/api/v1/users`, {
    method: 'POST',
    body: JSON.stringify({email, name, password})
  })
  .then( async (response) => {
    if( ![201, 422, 403].includes(response.status)){
      throw new Error(response.statusText);
    }

    const jsonData = await response.json(); 

    if(response.status === 201){
      dispatch(setMessage(
        'Cadastro criado, verifique o recebimento de um e-mail para confirmar sua conta.', 
        FeedbackType.Success
      ))
    }
    if(response.status === 403){
      dispatch(setMessage(jsonData && jsonData.errors, FeedbackType.Error))
    }
    if(response.status === 422){
      dispatch(setMessage(jsonData && jsonData.errors.join(', '), FeedbackType.Error))
    }
    
    dispatch(cancelFetching());
  })
  .catch(() => dispatch(cancelFetching()));
}
/**
 * 
 */
const loadDrafts = () : ThunkResult<void> => (dispatch) => {
  dispatch(AC_loadDrafts(getDrafts()));
}
/**
 * 
 * @param details 
 * @param itemId 
 * @param isFeedback 
 */
const submitAnswer = (
  details: string, itemId: number, isFeedback: boolean) : ThunkResult<void> => 
  (dispatch, getState) => {

    const trimmedText = details.trim().replace(/\s+/, " ");

    if(trimmedText.length < 10){
      dispatch(setMessage(t.answer_too_short, FeedbackType.Error))
      return;
    }

    if(!navigator.onLine){
      const answerToStash:StashedAnswer = {
        answerId: isFeedback ? itemId : null,
        questionId: isFeedback ? null : itemId,
        details: details
      }
      persistStashedAnswers(answerToStash);
      dispatch(clearDraft(itemId));
      dispatch(setMessage(t.answer_saved, FeedbackType.Success));

      if(isFeedback){ 
        dispatch(offlineFeedbackSalved( itemId, details ));
        persistAnswersFeedback(getState().answersFeedback);
      }

      return;
    }

    dispatch(fetching());
    
    fetch(`${SERVER_URL}/api/v1/answers${isFeedback ? `/${itemId}`:''}`, {
      method: isFeedback ? "PUT" : "POST",
      headers: { 'Authorization': `Bearer ${getState().token}`},
      body: isFeedback
        ? JSON.stringify({ answer: { details: trimmedText }})      
        : JSON.stringify({ answer: { details: trimmedText, question_id: itemId }})      
    })
    .then( async (response) => {
      if( ![201, 200, 422].includes(response.status)){ 
        throw new Error(response.statusText) 
      }
      
      const json = await response.json();

      if( response.status === 201 || response.status === 200){
        if( response.status === 201) dispatch(clearDraft(itemId));
        if( response.status === 200) dispatch(clearFeedbackAnswer(itemId));
        dispatch(setMessage("Sugestão enviada com sucesso", FeedbackType.Success))
      }else{
        if(json.errors && json.errors.hasOwnProperty('reason')){
          dispatch(setMessage(json.errors.reason, FeedbackType.Error))
        }
        // TODO
      }
      dispatch(cancelFetching());
    })
    .catch( e => {
      /* TODO */
      dispatch(cancelFetching());
    })
}
/**
 * 
 * @param skipResults 
 * @param showFeedback 
 */
const fetchAnswers = (skipResults:number, showFeedback: boolean):ThunkResult<void> => 
  (dispatch, getState) => {

  if(!navigator.onLine){    
    dispatch(setAnswers(getAnswers()));
    if(showFeedback){
      dispatch(setMessage(t.not_online, FeedbackType.Warning));
    }
    return;
  }

  dispatch(fetching());

  fetch(`${SERVER_URL}/api/v1/answers?skip=${skipResults}`, {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${getState().token}`}
  })
  .then( async (response) => {
    if(! response.ok ){
      throw new Error(response.statusText);
    }

    const json = await response.json();

    if(json && json.length > 0){
      if(skipResults === 0){
        dispatch(setAnswers(json));
        persistAnswers(json);
      }else{
        dispatch(appendAnswers(json));
      }
    }else{
      if( getState().answers.length === 0){
        dispatch(setAnswers([]))
      }
    }
    dispatch(cancelFetching());
    if(showFeedback){
      dispatch(setMessage("Sugestões atualizadas com sucesso", FeedbackType.Success));
    }
  })
  .catch( () => dispatch(cancelFetching()));
}
/**
 * 
 */
const fetchAnswerFeedback = ():ThunkResult<void> => (dispatch, getState) => {
  if(!navigator.onLine){
    dispatch(setFeedBackAnswers(getAnswersFeedback()));
    return;
  }

  dispatch(fetching());

  fetch(`${SERVER_URL}/api/v1/answers/pending`, {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${getState().token}`}
  })
  .then( async (response) => {
    if(response.status !== 200 && response.status !== 401){
      throw new Error(response.statusText);
    }

    const jsonData = await response.json();

    persistAnswersFeedback(jsonData || []);
    dispatch(setFeedBackAnswers(jsonData || []))

    dispatch(cancelFetching());
  }).catch( e => dispatch(cancelFetching()));
}
/**
 * 
 * @param passwordConfirmation 
 * @param prizeId 
 */
const submitPrizeTransaction = (
  passwordConfirmation:string, prizeId:number):ThunkResult<void> => (dispatch, getState) => {

  dispatch(fetching())

  fetch(`${SERVER_URL}/api/v1/prizes/transaction`, {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${getState().token}`},
    body: JSON.stringify(
      { prize_id: prizeId, password_confirmation: passwordConfirmation }
    )
  })
  .then( async (response) => {
    if(![403, 404, 201].includes(response.status)){ 
      throw new Error(response.statusText) 
    }

    let json = await response.json();

    if(response.status === 201){
      dispatch(setUserData({...getState().userData, balance: json.balance}))
      dispatch(setMessage("Solicição realizada com sucesso", FeedbackType.Success));
    }else{
      if( json.hasOwnProperty('balance')) {
        dispatch(setUserData({ ...getState().userData, balance: json.balance }))  
      }
      dispatch(setMessage(json.error, FeedbackType.Error));
    }
    dispatch(cancelFetching());
  })
  .catch( e => {
    dispatch(cancelFetching());
    dispatch(setMessage(e.message, FeedbackType.Error));
  })
} 
/**
 * 
 */
const fetchUserData = ():ThunkResult<void> => (dispatch, getState) => {
  if(Date.now() - MIN_RESYNC_TIME < getState().syncTracker.user) return;
  
  if(!navigator.onLine){
    dispatch(setUserData(getUserData()));
    return;
  }  

  fetch(`${SERVER_URL}/api/v1/users/my-profile`, {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${getState().token}`},
  }).then( async( response ) => {
    if(![ 200, 401].includes( response.status )) throw new Error(response.statusText);

    if(response.status === 401) dispatch(logout());
    if(response.status === 200){
      let json = await response.json();
      persistUserData(json);
      dispatch(setUserData(json));
    }

  }).catch( f => f)
}
/**
 * 
 */
const logout = ():ThunkResult<void> => (dispatch) => {
  purgePersistedData();
  dispatch(doLogout());
}

/** * 
 * @param email 
 */
const passwordRecovery = (email:string) : ThunkResult<void> => (dispatch) => {
  
  if( !navigator.onLine ){
    dispatch(setMessage(t.not_online, FeedbackType.Error));
    return;
  }

  dispatch(fetching());

  fetch(`${SERVER_URL}/api/v1/users/reset-password`, {
    method: 'POST',
    body: JSON.stringify({email})
  })
  .then( async (response) => {
    if(![200, 404].includes(response.status)){
      throw new Error(response.statusText);
    }

    if(response.status === 200) dispatch(setMessage(t.email_sent, FeedbackType.Success))

    if(response.status === 404 ) dispatch(setMessage(t.user_not_found, FeedbackType.Error))
    
    dispatch(cancelFetching());
  })
  .catch(() => dispatch(cancelFetching()));
}

/**
 * 
 */
const fetchTransactions = (skipResults: number, showFeedback: boolean) : ThunkResult<void> => 
  (dispatch, getState) => {

  if(!navigator.onLine){ 
    if(showFeedback) dispatch(setMessage(t.not_online, FeedbackType.Error));
    return;
  }

  dispatch(fetching());

  fetch(`${SERVER_URL}/api/v1/transactions?skip=${skipResults}`, {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${getState().token}`},
  })
  .then( async (response) => {
    if(![200, 401].includes(response.status)){
      throw new Error(response.statusText)
    }

    const jsonData = await response.json();
    if(response.status === 200){
      if(skipResults > 0){
        dispatch(appendTransactions(jsonData));
      }else{
        dispatch(setTransactions(jsonData));
      }

      if(showFeedback){
        dispatch(setMessage("Solicitações atualizadas com sucesso", FeedbackType.Success));
      }
    }

    if(response.status === 401){
      dispatch(logout());
    }

    dispatch(cancelFetching());
  })
  .catch(() => dispatch(cancelFetching()))
}

/**
 * 
 * @param id 
 */
const cancelTransaction = (id: number, password: string) : ThunkResult<void> => (dispatch, getState) => {
  if(!navigator.onLine){ 
    dispatch(setMessage(t.not_online, FeedbackType.Error));
    return;
  }

  dispatch(fetching());

  fetch(`${SERVER_URL}/api/v1/transactions/${id}/cancel`, {
    method: 'POST',
    body: JSON.stringify({password_confirmation: password}),
    headers: { 'Authorization': `Bearer ${getState().token}`},
  })
  .then( async (response) => {
    if(![200, 401, 403, 404].includes(response.status)){
      throw new Error(response.statusText)
    }

    const jsonData = await response.json();

    if(response.status === 200){      
      if( jsonData.hasOwnProperty('balance')) {
        dispatch(setUserData({ ...getState().userData, balance: jsonData.balance }))  
      }
      dispatch(fetchTransactions(0,false));
      dispatch(setMessage("Solicitação cancelada", FeedbackType.Success));      
    }

    if(response.status === 401) dispatch(logout());

    if(response.status === 403 || response.status === 404 ) {
      if(jsonData.errors && jsonData.errors.reason){
        dispatch(setMessage(jsonData.errors.reason, FeedbackType.Error))
      }
    }

    dispatch(cancelFetching());
  })
  .catch(() => dispatch(cancelFetching()))
}

export {
  fetchPrizes,
  logout,
  fetchQuestions,
  loadDrafts,
  authUser,
  submitAnswer,
  fetchUserData,
  submitPrizeTransaction,
  fetchAnswers,
  fetchAnswerFeedback,
  registerUser,
  passwordRecovery,
  fetchTransactions,
  cancelTransaction
}