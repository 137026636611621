import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState, FeedbackType } from '../app-types';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { clearMessage, setMessage } from '../datastore/generalActions';

const FeedBack = () => {
  const {message, type}:{message:string, type:any} = useSelector((state:ReduxState) => state.feedback);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(clearMessage())
  }

  useEffect(() => {
    const queryParams = window.location.search;
    let found = queryParams.match(/\?(?<param>.*)=(?<value>.*)/)

    if(found && found.groups && found.groups.param === "confirmation_message"){
      dispatch(setMessage( decodeURIComponent(found.groups.value), FeedbackType.Success ));
      window.history.replaceState({}, "", "/#")
    }
  }, [dispatch]);
  
  return(
    message === ""
      ? null
      : (
        <Snackbar 
          autoHideDuration={10000} 
          onClose={handleClose} 
          open={message !== ''} 
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >

          <Alert onClose={handleClose} severity={type} variant={"filled"}>
            {message}
          </Alert>
        </Snackbar>
      )
  )
}

export default FeedBack;