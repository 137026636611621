
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Search from '@material-ui/icons/Search'
import { Typography } from '@material-ui/core';


const EmptyArea = ({text}:{text:string}) => {

  return(
   <div className={css(styles.area)}>
    <div className={css(styles.areaIcon)}>
      <Search fontSize="large" style={{ fontSize: 64,
    color: '#BDBDBD' }} />
    </div>
    <Typography variant="body1" component="span" className={css(styles.textEmpty)}
    >{text}</Typography>
   </div>

  );

}

export default EmptyArea;

const styles = StyleSheet.create({

  area:{
    width: '100%',
    height: 'calc(100% - 0.5em - 36px - 48px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  areaIcon: {
    margin: '40px 0px 40px 0px',
  },
  iconEmpty: {
    fontSize: '64px',
    color: '#BDBDBD',
  },
  textEmpty: {
    color: '#BDBDBD',
  }
});

  
