
import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Typography from '@material-ui/core/Typography';

function Feedback ({ feedback_message, isOutline }:{feedback_message: string | undefined, isOutline: boolean}, 
  ) {

  return (
    feedback_message  ? 
       <div className={ isOutline ? css(styles.contentOutliner) : css(styles.contentFeedback)}>
         <Typography className={ isOutline ? css(styles.textColorOutliner) :  css(styles.titleFeedback)} variant="caption">Feedback</Typography>
         <Typography className={ css( styles.textItalic,  ( isOutline ? styles.textColorOutliner : styles.textFeedback ))} variant="body2"
          align="center"
  > "{feedback_message}"</Typography>
      </div>

    : null
   
   
  );
  

}

export default Feedback;   

    
const styles = StyleSheet.create({    
  titleFeedback: {
    color: 'var(--surface-enabled-color)'
  },
  textColorOutliner: {
    color: 'var(--text-primary-color)'
  },
  contentOutliner:{
    border: '1px solid var(--text-primary-color)',
    padding: '6px 10px 10px 10px',
    backgroundColor: 'transparent',
    margin: '20px 0px'
  },

  contentFeedback: {
    backgroundColor: 'var(--surface-focused-color)',
    padding: '6px 10px 10px 10px',
    margin: '20px 0px'
  },
  textItalic: {
    fontStyle: 'italic',
  },
  textFeedback: {
    color: 'var(--primary-color-light)'
  }


});

