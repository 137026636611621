import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import debounce from 'lodash/debounce';
import { useSelector, useDispatch } from 'react-redux';
import { IAnswerDraft, ReduxState, IQuestion, FeedbackType, Answer } from '../../app-types';
import { saveDraft, setMessage } from '../../datastore/generalActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Redirect, useLocation } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import {createStyles,Theme,withStyles,makeStyles,} from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { submitAnswer } from '../../datastore/thunk-actions';
import AreaFeedback from '../Components/Feedback';

const debouncedCallback = debounce((callback, value) => {
  callback(value);
}, 1000)

const FormAnswer = () => {
  const { id } = useParams();
  const itemId = Number.parseInt(id);
  const [details, setDetails] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isQuestion = location.pathname.search('/questao/') >= 0;
  const [isFetching, message, messageType] = useSelector((state:ReduxState) => {
    return [state.isFetching, state.feedback.message, state.feedback.type];
  });
  const originalAnswer : Answer | null = useSelector((state:ReduxState) => {
    if(isQuestion) return null;
    return state.answersFeedback.filter(item => item.id = itemId)[0]
  });

  const draft : IAnswerDraft | null = useSelector((state: ReduxState) => {
    if(!isQuestion) return null;
    return state.drafts.filter( draft => draft.questionId === itemId)[0] || {}
  });
  const questionData:IQuestion = useSelector((state:ReduxState) => {
    return state.questions.find( ea => ea.id === itemId) || {id:null, title: '', body: '', answers_count: 0}
  });

  useEffect(() => {
    const originalDetails = originalAnswer && originalAnswer.details;
    if(!isQuestion) setDetails(originalDetails || '');
  }, [isQuestion, originalAnswer ])
  
  useEffect(() => {
    if( !isQuestion ) return;
    if( draft && draft.hasOwnProperty('details')) { setDetails(draft.details)};
  }, [isQuestion, draft]); 

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDetails(event.target.value);
    if(!isQuestion) return;
    debouncedCallback(performSaveDraft, event.target.value);
  }

  const performSaveDraft = (value: string) => {
    dispatch(saveDraft( itemId, value ))
  }

  const classes = stylesMaterialUi();

  const submitHandler = () => {
    if(!isQuestion && originalAnswer && details === originalAnswer.details){
      dispatch(setMessage("É necessário modificar a sugestão", FeedbackType.Error));
      return;
    }

    dispatch(submitAnswer(details, itemId, !isQuestion))
  };

  const focusHandler = () => {
    setTimeout(() => {
      const el = document.querySelector('#standard-secondary');
      el && el.scrollIntoView(true);
    }, 1000);
  }

  useEffect(() => {
    if(!isQuestion && !originalAnswer){
      history.replace('/minhas-ideias');
    }
  },[isQuestion, originalAnswer, history]);

  return(
  ( message !== '' && messageType === FeedbackType.Success )
  ? <Redirect to="/home"/>
  : (
    <div className={css(styles.cardPrizes)}>
      <div className={css(styles.contentCard)}>
        <Typography 
          className={css(styles.title)} 
          variant="subtitle1" 
          component="h2">
          {isQuestion ? questionData.title : originalAnswer && originalAnswer.original_title }
        </Typography>

        {
          isQuestion 
          ? (
            <div 
              className="contentCardFormAnswer" 
              dangerouslySetInnerHTML={{__html: questionData.body}}>
            </div>
          ) 
          : (
            <AreaFeedback 
              feedback_message={(originalAnswer && originalAnswer.feedback_message) || ''} 
              isOutline={true} 
            />
          )
        }

        <CssTextField
          label="Deixe aqui sua sugestão"
          className={css(styles.contentTextField)}
          id="standard-secondary"
          value={details}
          multiline
          color="secondary"
          required
          onFocus={focusHandler}
          onChange={onChangeHandler}
          InputProps={{ className: classes.textarea  }}
          InputLabelProps = {{ className: classes.label }}/>

        <Button 
          className={css(styles.btnSendAnswer)}
          disabled={isFetching}
          onClick={submitHandler}
          variant="contained">
          
          {
            isQuestion
            ? 'Enviar Sugestão'
            : 'Enviar Melhoria'
          }
          
        </Button>
      </div>
    </div>
    )
  );
}

const stylesMaterialUi = makeStyles((theme: Theme) =>
  createStyles({
    textarea: {
      color: '#debb55'
    },
    label: {
      color: '#debb55'
    },
    div: {
      color: '#debb55'
    },
  }),
);

const CssTextField = withStyles({
  root: {
      '& .MuiInputBase-input': {
        color: 'theme.palette.secondary'
      },
      '& .MuiFormLabel-root':{
        color: 'theme.palette.secondary'
      },
      '& .MuiInput-input':{
        color: 'theme.palette.secondary'
      },
      '& .MuiInput-underline:after':{
        border: '1px solid #debb55',
      }
    },
})(TextField);

const styles = StyleSheet.create({

  img: {
    width: '24px !important',
    height: '24px !important'
  },

  cardPrizes: {
    backgroundColor: 'var(--primary-color-dark)',
    color: 'var(--text-primary-color)',
    padding: '1em',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },

  contentCard: {
    width: '800px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    font: '18px var(--font-primary)',
    backgroundColor: 'var(--primary-color-light)',
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',

  },

  btnSendAnswer: {
    backgroundColor: 'var(--secondary-color-default)',
    color: 'var(--text-primary-color)',
    ':hover': {
      backgroundColor: 'var(--secondary-color-light)',
    },
    width: '170px',
    margin: '1em 0',
    display: 'flex',
    alignSelf: 'flex-end',
  },
  inputProps: {
    color: 'var(--text-primary-color)', 
  },
  contentTextField: {
    color: 'var(--secondary-color-default)',
    'label': {
      color: 'var(--secondary-color-default) !important'
    }
  }
});

export default FormAnswer;