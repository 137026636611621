import { RootReducer } from '../datastore/reducers';

/**
 * Interface of a redux action
 * @param type string type of the action to be called
 * @param payload all the data needed to excute the operation
 */
export interface IReduxAction {
    type: string;
    payload?: any
}

/**
 * Interface for a prize
 * @param id identifier of the prize in the server
 * @param description prize details
 * @param value prize value, used when generating trade transaction
 * @param publishedUntil prize deadline to receive trade transactions
 * @param mediaUrl image address of the media of the prize
 */
export interface IPrize {
    id: number; 
    description: string; 
    value: number;
    publishedUntil: Date;
    media_url:	string ;
}

/**
 * Interface for Questions
 * @param id question identifier as in the server
 * @param title title of the question
 * @param body question content
 * @param publishedUntil deadline for suggestion submissions
 * @param maxScore the maximum point value for suggestions to this question
 */
export interface IQuestion {
  id: number | null,
  title: string,
  body: string,
  publishedUntil?: Date,
  maxScore?: number,
  answers_count: number,
  category_description?: string
}

/**
 * Interface for the syncTracker object
 * @param prize timestamp for the last time the prizes list was synchronized
 * @param suggestion timestamp for the last time the suggestions list was synchronized
 * @param user timestamp for the last time the user date was synchronized
 * @param question timestamp for the last time the questions list was synchronized
 * @param drafts timestamp for the last time the draft list was persisted
 */
export interface ISyncTracker {
  prize: number,
  suggestion: number,
  user: number,
  question: number,
  drafts: number,
  answers: number
  answersFeedback: number
}

/**
 * Interface for Answers drafts
 * @param questionId problem identifier
 * @param details the draft text
 * @param salvedAt the date the draft was saved
 */
export interface IAnswerDraft {
  questionId: number,
  details: string,
  salvedAt: Date
}

/**
 * Interface for the pesistance reduxers
 * @param drafts used to control the draft persistance routine
 */
export interface IPersistanceTracker {
  drafts: boolean
}

export enum FeedbackType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error'
}

export type User = {
  name:string
  balance:number,
  answers: {
    sent:number,
    pending:number
    feedback:number
  }
}

export type Answer = {
  id: number,
  question_id: number,
  original_title: string,
  details: string,
  score?: number,
  feedback_message?: string,
  is_locked: boolean,
  is_pending_evaluation: boolean,
  allow_edit: boolean
}

export type StashedAnswer = {
  questionId: number | null,
  answerId: number | null,
  details: string
}

export type Feedback = {message:string, type: FeedbackType | ''}
export type ReduxState = RootReducer;


/**
 * Interface for a transaction
 *  @param id: identificador;
    @param description: descrição da transação;
    @param value: valor da transação;
    @param is_complete: indica se a trnasação esta completa.
    @param canceled_at: Data em que foi cancelado;
    @param created_at: data em que foi criado;
 */
export type Transaction = {
    id: number,
    description: string,
    value: number,
    is_complete: boolean,
    canceled_at:  Date | null,
    voucher: string | null,
    created_at: Date
}
